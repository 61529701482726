import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import VueDragscroll from 'vue-dragscroll';
import VueScrollTo from 'vue-scrollto';
import './assets/custom.css';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { store } from './store.js';
import './helpers/directives.js'
import dayjs from "dayjs";
import Vuelidate from 'vuelidate'
import 'material-icons/iconfont/material-icons.css';
import '@mdi/font/css/materialdesignicons.css'
Vue.use(DatePicker, {
  componentPrefix: 'vc',
});

Vue.component('date-picker', DatePicker);
Vue.config.productionTip = false;

Vue.use(VueDragscroll);
Vue.use(VueScrollTo);
dayjs.locale('ru');


Vue.use(Vuelidate)

new Vue({
  store,
  vuetify,
  router,
  render: (h) => h(App),
}).$mount('#app');
