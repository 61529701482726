<template>
  <div class="video" style="display:none">
    <div class="text-center">
      <h2 class="description leading-[48px] font-[500] text-4xl">
        Видео о мед. центре «Eclinic»
      </h2>
      <div class="video_page">
        <iframe
          class="rounded-lg"
          width="100%"
          height="100%"
          :src="videoUrl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoClinic',
  props: {
    video: Array,
  },
  computed: {
    videoUrl() {
      const videoItem = this.video?.length
        ? this.video.find((item) => item.type === 'youtube_video')
        : {};
      return videoItem ? videoItem.value : '';
    },
  },
};
</script>

<style scoped>
.video {
  @apply px-[106px] flex justify-center items-center mb-6;
}

.video_page {
  @apply mt-6 border-none rounded-lg w-[812px] h-[461px];
}

@media (max-width: 640px) {
  .video {
    @apply py-0 px-5 flex justify-center items-center;
  }
  .description {
    @apply text-2xl;
  }
  .video_page {
    @apply mt-6 border-none rounded-lg w-[345px] h-[197px];
  }
}
</style>
