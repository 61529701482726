<template>
  <v-dialog
      v-model="dialogVisible"
      max-width="491px"
      max-height="616px"
      rounded-xl
  >
    <template v-slot:activator="{ on, attrs }">
      <button
          v-bind="attrs"
          v-on="on"
          class="call-btn"
      >
        {{ textBtn }}
      </button>
    </template>
    <div class="w-full h-full p-12 bg-white rounded-xl relative">
      <button
          class="border-[#E8E8E8] right-0 top-0 mt-2 mr-2 absolute p-2 border-[1px] rounded-lg"
          @click="dialogVisible = false"
      >
        <v-icon>mdi-close</v-icon>
      </button>
      <h2 class="text-4xl">Заказать обратный звонок</h2>
      <h4 class="mt-4">Мы перезвоним вам в течение нескольких минут.</h4>
      <div class="mt-9 grid gap-5">
        <div>
          <h4 class="">Номер телефона</h4>
          <div class="input">
            <div class="border rounded-lg">
              <input
                  v-model="$v.inputValuePhone.$model"
                  class="w-full border pl-3 py-2 outline-none"
                  type="tel"
                  v-mask="'+7 (###) ###-##-##'"
                  placeholder="+7 ("
                  required
              />
            </div>
            <span class="vuelidate-error"
                  v-if="$v.inputValuePhone.$dirty && !$v.inputValuePhone.required"
            >Обязательное поле</span>
            <span class="vuelidate-error"
                  v-if="$v.inputValuePhone.$dirty && !$v.inputValuePhone.minLength"
            >Неверный формат</span>
          </div>
          <span
              v-if="isInputValueNumberEmpty"
              class="text-sm font-[500] text-red-500"
          >
						Номер телефона введён некорректно
					</span>
        </div>
        <div>
          <h4>Примечание</h4>
          <v-textarea
              v-model="inputValueText"
              height="88"
              class="mt-2 rounded-lg border border-gray-200 p-4"
              shaped
              variant="outlined"
              flat
              solo
              auto-grow
              required
              maxlength="148"
          ></v-textarea>

        </div>

        <div>
          <vue-recaptcha
              ref="recaptcha"
              :sitekey="siteKey"
              @verify="verify"
              @expired="onCaptchaExpired"
          />
          <div class="vuelidate-error text-left"
               v-if="$v.recaptcha.$dirty && !$v.recaptcha.required"
          >Обязательное поле</div>
        </div>
      </div>
      <p class="text-[13px] mt-9">
        Нажимая «Перезвоните мне», я подтверждаю, что даю свое согласие на
        обработку
        <a target="_blank" :href="personalDataLink" alt="personal-data">персональных данных</a>
      </p>
      <v-btn
          @click="submitForm"
          block
          class="rounded-lg mt-5 normal-case"
          color="success"
      ><h4 class="text-base">Перезвоните мне</h4></v-btn
      >
    </div>
  </v-dialog>
</template>

<script>
import {mask} from "vue-the-mask"
import VueRecaptcha from "vue-recaptcha";
import {reCaptchaMixin} from "@/mixins/reCaptchaMixin.js";
import {axiosInstance} from "@/axiosConfig.js";
import {minLength, required} from "vuelidate/lib/validators/index.js";
import {mapState} from "vuex";

export default {
  components: {VueRecaptcha},
  props: ['textBtn'],
  mixins: [reCaptchaMixin],
  data() {
    return {
      inputValuePhone: "",
      inputValueText: "",

      dialogVisible: false,
      isInputValueNumberEmpty: false, // Добавленное свойство
      isInputValueTextEmpty: false,
    }
  },
  directives: {mask},
  methods: {
    async submitForm(event) {
      event.preventDefault()

      this.$v.$touch()
      if (this.$v.$invalid || this.$v.$error ) {
        // console.log('invalid')
        return
      }

      const payload = {
        phone_number: this.inputValuePhone,
        comment: this.inputValueText,
        recaptcha_response: this.recaptcha
      }

      try {
        const response = await axiosInstance.post(
            "clinic/callback/",
            payload
        )
        if (response.status === 201) {
          this.dialogVisible = false
          this.inputValuePhone = "+7 ("
          this.inputValueText = ""
          ym(67177204,'reachGoal','obratnyzvonok')
        }
      } catch (error) {
        console.error("Error:", error)
      }
    },
  },
  validations() {
    return {
      recaptcha: {
        required,
      },
      inputValuePhone: {
        required,
        minLength: minLength(18)
      },
    }
  },
  computed: {
    ...mapState({
      personalDataLink: ({personalDataLink}) => personalDataLink
    }),
    buttonClass() {
      return {
        underline: !this.isFooter,
        "decoration-dashed": !this.isFooter,
        "underline-offset-8": !this.isFooter,
        "hover:text-green-400": !this.isFooter,
        "hover:border-green-400": !this.isFooter,
        "text-green-400": this.isFooter,
        "border-green-400": this.isFooter,
      }
    },

  },
}
</script>

<style scoped>
.v-application .call-btn {
  @apply border-b border-[#1C1C1C] border-dashed hover:text-green-400 hover:border-green-400 order-last sm:order-first
}

a {
  @apply text-green-500;
}
</style>
