<template>
  <div class="networks container">
    <div class="header">
      <div class="header-test">
        <h2 class="header-desc text-4xl leading-[48px] font-[500] mr-9">
          Мы в соцсетях
        </h2>
        <div
            class="btns border border-[#C2E1BF] rounded-lg flex items-center bg-[#E5F8EB]"
        >
          <button
              :class="[
							activeTab === 'vk'
								? 'border h-full rounded-lg py-[6px] px-3 text-white bg-[#4EA846] font-[500]'
								: 'border h-full rounded-lg py-[6px] px-3 font-[500]',
						]"
              @click="activeTab = 'vk'"
          >
            Вконтакте
          </button>
          <button
              :class="[
							activeTab === 'telegram'
								? 'border h-full rounded-lg py-1 px-4 text-white bg-[#4EA846] font-[500]'
								: 'border h-full rounded-lg py-1 px-4 font-[500]',
						]"
              @click="activeTab = 'telegram'"
          >
            Telegram
          </button>
        </div>
      </div>


    </div>
    <div class="stocks">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
              v-for="(item, index) in filteredSocialItems"
              :key="index"
              class="swiper-slide social-slide"
          >
            <a :href="item.url">
              <img
                  :src="item.image"
                  alt="social"
                  class="border rounded-lg cursor-pointer w-full"
              />
            </a>
            <a :href="item.url">
              <h3 class="text-black text-xl mt-5">{{ item.label }}</h3>
            </a>
            <!--						<h5 class="text-[#969696]">{{ item.date }}</h5>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper"
import {mapState} from "vuex";

export default {
  name: "SocialNetwork",
  props: {
    messengers: Array,
  },
  data() {
    return {
      activeTab: "vk",
      swiper: null,
      vk: "",
      // socialItems: [], // Empty array to hold the data
    }
  },
  computed: {
    ...mapState({
      posts: state => state.posts
    }),
    filteredSocialItems() {
      return this.posts.filter(item => item.social_media === this.activeTab)
    },
  },
  watch: {
    posts() {
      this.initializeSwiper()
    }
  },
  mounted() {
    // this.fetchData()

    this.initializeSwiper()

    // if (this.messengers?.length > 0) {
    //   this.vk = this.messengers[0].link
    // }
  },
  methods: {
    initializeSwiper() {
      this.$nextTick(() => {
        this.swiper = new Swiper(".swiper-container", {

          spaceBetween: 16,
          breakpoints: {
            320: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            900: {
              slidesPerView: 4,
            }
          }
        })
      })
    },
    // Method to fetch data

  },
}
</script>

<style scoped>
.networks {
  @apply py-[100px] ;
}

.stocks {
  @apply mt-6 overflow-hidden;
}

.header {
  @apply text-center flex justify-between;
}

.header-test {
  @apply flex;
}

.referral {
  @apply text-[#4EA846] flex items-center;
}

@media (max-width: 640px) {
  .social-slide {

    max-width: 100%;
  }

  .networks {
    @apply py-[56px] px-5;
  }

  .header {
    @apply text-left grid grid-cols-1;
  }

  .header-test {
    @apply flex-col gap-3;
  }

  .header-desc {
    @apply text-2xl;
  }

  .btns {
    @apply w-[210px];
  }

  .referral {
    @apply hidden;
  }
}
</style>
