<template>
  <v-app>
    <div class="flex flex-col min-h-screen">
      <Header/>
      <main class="w-full overflow-x-hidden main">
        <router-view></router-view>
      </main>
      <Footer @open-dialog="showDialog = true"/>
    </div>
    <v-btn
        v-scroll="onScroll"
        v-show="fab"
        fab
        fixed
        bottom
        right
        color="#4caf50"
        @click="toTop"
    >
      <v-icon color="white">keyboard_arrow_up</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import Header from "./components/header-footer/header/Header.vue"
import Footer from "./components/header-footer/footer/Footer.vue"
import {mapActions, mapState} from "vuex"
import {axiosInstance} from "./axiosConfig.js"
import '@/plugins/goggleTag.js'
import {arrayUniqueByKey} from "@/helpers/ArrayUniqueByKey.js";


export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      fab: false
    }
  },
  mounted() {
    (async () => {
      this.$store.commit('updateOption', {option: 'currentRout', payload: this.$route.name})
      await this.getClinicInfo()
      await this.getDoctors()
      await this.getServices()
      await this.getReviews()

      await this.getAdvertising()
      await this.getPosts()

      let {utm_source, utm_campaign} = this.$route.query

      if (utm_source) {
        this.$store.commit('updateOption', {option: 'utm_source', payload: utm_source})
      }

      if (utm_campaign) {
        this.$store.commit('updateOption', {option: 'utm_campaign', payload: utm_campaign})
      }

    })()
  },
  computed: {
    ...mapState({
      utm_source: state => state.utm_source,
      utm_campaign: state => state.utm_campaign,
    }),
  },
  watch: {
    '$route': {
      async handler(val) {
        this.$store.commit('updateOption', {option: 'currentRout', payload: this.$route.name})
        if (val.name == 'main' || val.name == 'discount') {
          await this.getAdvertising()
        }
      }, immediate: true
    }
  },
  methods: {
    ...mapActions(["getClinicInfo", 'getAdvertising', 'getPosts']),
    async getDoctors() {
      try {
        const res = await axiosInstance.get("schedule/doctors/?format=json")
        this.$store.commit("updateOption", {
          option: "doctors",
          payload: res.data,
        })
        let specializationsFromDoctor = [...new Set(
            res.data.flatMap(doctor =>
                doctor.specialization.map(specialty => specialty)
            )
        )]

        specializationsFromDoctor = arrayUniqueByKey(specializationsFromDoctor.map(specialty => {

          return specialty
        }).filter(el => el), 'id')
        this.$store.commit("updateOption", {
          option: "doctors",
          payload: res.data,
        })

        this.$store.commit("updateOption", {
          option: "doctorsSpecializations",
          payload: specializationsFromDoctor,
        })

      } catch (error) {
        console.error(error)
      }
    },
    async getServices() {
      await axiosInstance.get("schedule/service-types/").then(response => {
        const data = response.data

        this.$store.commit("updateOption", {
          option: "services",
          payload: data,
        })
      })
    },
    async getReviews() {
      try {
        let {data} = await axiosInstance.get('clinic/reviews/')
        this.$store.commit('updateOption', {option: 'reviews', payload: data})
      } catch (e) {
        console.log(e)
      }
    },
    onScroll(e) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    }
  },
}
</script>

<style lang="css">
@import "@/assets/css/tailwind.css";

.v-btn {
  letter-spacing: 0em;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}


</style>
