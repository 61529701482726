var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"bg-[#F7F7F7] bg-cover rounded-t-lg",style:('background-image: url(' + _vm.doctor.image + ')')}),_c('div',{staticClass:"p-3 flex flex-col gap-3"},[_c('h2',{staticClass:"font-[500] text-xl"},[_vm._v(_vm._s(_vm.doctor.name))]),_c('div',{staticClass:"mt-1"},_vm._l((_vm.doctor.specialization),function(specialty,index){return _c('span',{key:index},[_c('router-link',{class:{'disabled-link' : _vm.disabledLink},attrs:{"to":{
            path: '/doctors',
            query: { specialty: specialty.service_type?.slug },
          }}},[_c('v-btn',{staticClass:"normal-case mr-1 mt-2 bg-[#E5F8EB] border border-[#C2E1BF]",staticStyle:{"font-size":"13px"},attrs:{"small":"","outlined":"","rounded":"","color":"success"},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(specialty.name)+" ")])],1)],1)}),0),_c('div',{staticClass:"flex gap-3 flex-1 items-end"},[_c('router-link',{staticClass:"min-w-[120px]",attrs:{"to":{
          name: `specialist`,
          params: {
            id: _vm.doctor.id,
            uuid: _vm.doctor.uuid,
          },
        }}},[_c('v-btn',{staticClass:"border border-[#E8E8E8] w-full normal-case rounded-lg",attrs:{"block":"","outlined":""},on:{"click":_vm.scrollToTop}},[_c('h4',{staticClass:"text-base"},[_vm._v("О враче")])])],1),_c('router-link',{staticClass:"w-full",attrs:{"to":{
          name: `specialist`,
          params: {
            id: _vm.doctor.id,
            uuid: _vm.doctor.uuid,
          },
        }}},[_c('v-btn',{staticClass:"w-full normal-case rounded-lg",attrs:{"block":"","color":"success"},on:{"click":_vm.scrollToTop}},[_c('h4',{staticClass:"text-base"},[_vm._v("Записаться")])])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }