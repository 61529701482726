<template>
  <section class="mob-wrapper">
    <div class="menu container">
      <div class="header">
        <h2 class="header-text leading-[64px]">
          Менеджмент здоровья
        </h2>

        <router-link to="/direction" class="link__appoint">
          <v-btn class="btn-appointment" color="success"
          ><h4 class="text-base">Записаться на приём</h4></v-btn
          >
        </router-link>
      </div>

      <img
          class="picture transition duration-150 ease-in-out"
          :src="doctors[currentSlideIndex]?.picture"
          :alt="doctors[currentSlideIndex]?.name"
          :class="{ 'fade-out': transitioning }"
          style="object-fit: cover; object-position: top"
      />

      <!--      <div class="slider">-->
      <div class="slide-panel" :class="{ 'transition-slide': transitioning }">
        <div class="flex justify-between mb-2">
          <h2
              class="doctor-name transition-fade"
              :class="{ 'fade-out': transitioning }"
          >
            {{ doctors[currentSlideIndex]?.name }}
          </h2>
          <div class="switcher-mobile">
            <v-btn
                id="кнопка переключения слайдера"
                class="bg-[#4EA846] hover:bg-[#abd6a7]"
                icon
                color="success"
                @click="nextSlide"
            >
              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>

        <div class="flex gap-2 flex-wrap">
						<span
                v-for="(specialty, id) in doctors[currentSlideIndex]?.specialties"
                :key="id"
            >
							<router-link
                  :to="{
									path: '/doctors',
									query: { specialty: specialty.service_type?.slug },
								}"
              >
								<v-btn
                    class="text-[#4EA846] normal-case bg-[#E5F8EB] border border-[#C2E1BF] transition-fade"
                    :class="{ 'fade-out': transitioning }"
                    outlined
                    small
                    rounded
                    style="font-size: 13px"
                    color="success"
                    id="Направление"
                >{{ specialty.name }}</v-btn
                >
							</router-link>
						</span>
        </div>

        <div class="look-doctors-mob">
          <router-link to="/doctors">
            <v-btn
                class="normal-case rounded-lg bg-white border border-[#E8E8E8]"
                outlined
                small
            >
              <h4 class="text-base">Смотреть всех врачей</h4>
            </v-btn>
          </router-link>
        </div>

        <div class="look-doctors">
          <router-link to="/doctors">
            <v-btn
                class="normal-case rounded-lg bg-white border border-[#E8E8E8]"
                outlined
            >
              <h4 class="text-base">Смотреть всех врачей</h4>
            </v-btn>
          </router-link>

          <div class="flex items-center justify-between">
            <div class="flex gap-2 mr-6">
              <button
                  @click="currentSlideIndex = 0"
                  :class="[
										currentSlideIndex === 0
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									]"
              ></button>

              <button
                  @click="currentSlideIndex = 1"
                  :class="[
										currentSlideIndex === 1
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									]"
              ></button>
              <button
                  @click="currentSlideIndex = 2"
                  :class="[
										currentSlideIndex === 2
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									]"
              ></button>
              <button
                  @click="currentSlideIndex = 3"
                  :class="[
										currentSlideIndex === 3
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									]"
              ></button>
            </div>

            <v-btn
                id="кнопка переключения слайдера"
                class="bg-[#4EA846] hover:bg-[#abd6a7]"
                icon
                color="success"
                @click="nextSlide"
            >
              <v-icon color="white">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
      <!--      </div>-->
    </div>
  </section>
</template>

<script>
import {mapState} from "vuex";
import gsap from 'gsap'

export default {
  name: "SlideMenuGen",

  data() {
    return {
      transitioning: false,
      currentSlideIndex: 0,
    }
  },
  mounted() {
    gsap.timeline()
        .from('.header-text', {
          y: 400,
          opacity: 0,
          duration: 1
        })
        .from('.link__appoint', {
          y: 200,
          opacity: 0,
        })
  },
  computed: {
    ...mapState({
      doctorsVuex: state => state.doctors
    }),
    doctors() {
      return this.doctorsVuex
          .map(doctor => ({
            id: doctor.id,
            name: doctor.name,
            specialties: doctor.specialization,
            picture: doctor.image,
          })).filter(el => !el.name.includes('Довженко') && el.id !== 9).slice(0, 4)
    }
  },
  methods: {
    nextSlide() {
      this.transitioning = true
      setTimeout(() => {
        this.currentSlideIndex++
        if (this.currentSlideIndex === this.doctors.length) {
          this.currentSlideIndex = 0
        }
        this.transitioning = false
      }, 200)
    },
  },
}
</script>
<style scoped>
.transition-slide {
  transition: transform 0.2s ease-in-out;
}

.fade-out {
  opacity: 0;
}

.fade-in {
  opacity: 1;
}

.menu {
  @apply w-full h-[640px]  flex relative;
}

.header {
  @apply absolute top-20 max-w-3xl  z-10   ;
}

.picture {
  @apply bottom-0 right-0 ml-auto ;
}

.header-text {
  @apply text-[56px] font-[500] ;
}

.btn-appointment {
  @apply mt-7 normal-case rounded-lg;
}

.slide-panel {
  transform: translate(-58%, 20%);
  height: max-content;
  @apply w-[468px] p-6 bg-[#FFFFFF] absolute z-10 rounded-[20px] mb-20 left-[50%] top-[50%] border;
}

.doctor-name {
  @apply font-[500] text-xl;
}

.slider {
  @apply flex items-center;
}

.switcher-mobile {
  @apply hidden;
}

.look-doctors {
  @apply flex justify-between items-center mt-6;
}

.look-doctors-mob {
  @apply hidden;
}

.mob-wrapper {
  @apply relative  bg-[#F7F7F7];
}

@media screen and (max-width: 900px) {
  .header {
    max-width: 500px;
    text-align: left;
    top: 40px;
  }

  .header-text {
    @apply text-5xl text-left mt-0;
  }
}

@media screen and (max-width: 808px) {
  .header {
    max-width: 400px;
    text-align: left;
  }


  .header-text {
    @apply text-4xl w-full text-left font-[500] mt-0;
  }
}

@media (max-width: 640px) {
  .menu {
    @apply w-full max-h-[568px] bg-[#F7F7F7] px-[15px]  static;
  }

  .header {
    left: 50%;
    top:8px;
    transform: translate(-50%, -0);
    @apply mt-0 max-w-[300px] w-full ;
  }

  .picture {
    @apply absolute bottom-0   w-full h-full;
  }

  .header-text {
    @apply text-2xl w-full text-center font-[500] mt-0;
  }

  .btn-appointment {
    @apply hidden;
  }

  .slide-panel {
    transform: translate(-50%, 68%);
    margin: 0;
    @apply w-[345px] p-4 bg-[#FFFFFF]  absolute z-10 rounded-[20px] bottom-0   ;
  }

  .doctor-name {
    @apply font-[500] text-base;
  }

  .slider {
    @apply flex items-start justify-between;
  }

  .switcher-mobile {
    @apply flex justify-center gap-2;
  }

  .look-doctors {
    @apply hidden;
  }

  .look-doctors-mob {
    @apply block  mt-4;
  }
}
</style>
