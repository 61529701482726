var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"networks container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"header-test"},[_c('h2',{staticClass:"header-desc text-4xl leading-[48px] font-[500] mr-9"},[_vm._v(" Мы в соцсетях ")]),_c('div',{staticClass:"btns border border-[#C2E1BF] rounded-lg flex items-center bg-[#E5F8EB]"},[_c('button',{class:[
							_vm.activeTab === 'vk'
								? 'border h-full rounded-lg py-[6px] px-3 text-white bg-[#4EA846] font-[500]'
								: 'border h-full rounded-lg py-[6px] px-3 font-[500]',
						],on:{"click":function($event){_vm.activeTab = 'vk'}}},[_vm._v(" Вконтакте ")]),_c('button',{class:[
							_vm.activeTab === 'telegram'
								? 'border h-full rounded-lg py-1 px-4 text-white bg-[#4EA846] font-[500]'
								: 'border h-full rounded-lg py-1 px-4 font-[500]',
						],on:{"click":function($event){_vm.activeTab = 'telegram'}}},[_vm._v(" Telegram ")])])])]),_c('div',{staticClass:"stocks"},[_c('div',{staticClass:"swiper-container"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.filteredSocialItems),function(item,index){return _c('div',{key:index,staticClass:"swiper-slide social-slide"},[_c('a',{attrs:{"href":item.url}},[_c('img',{staticClass:"border rounded-lg cursor-pointer w-full",attrs:{"src":item.image,"alt":"social"}})]),_c('a',{attrs:{"href":item.url}},[_c('h3',{staticClass:"text-black text-xl mt-5"},[_vm._v(_vm._s(item.label))])])])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }