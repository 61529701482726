import dayjs from 'dayjs';
import Vue from 'vue';
import Vuex from 'vuex';

import IsoWeek from 'dayjs/plugin/isoWeek';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrBefore);

dayjs.extend(IsoWeek);

import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
Vue.use(Vuex);
import {axiosInstance} from './axiosConfig';
import {arrayUniqueByKey} from "@/helpers/ArrayUniqueByKey.js";

require('dayjs/locale/ru')
let localeData = require('dayjs/plugin/localeData')
dayjs.locale('ru');
dayjs.extend(localeData)

export const store = new Vuex.Store({
  state: {
    currentRout: '',
    clinicInfo: [],
    referrals: [],
    doctors: [],
    services: [],
    // referralSelectedTab: '',
    reviews: [],
    selectedReferral: '',
    referralTabs: [
      {
        name: 'adults', title: 'Взрослым'
      },
      {
        name: 'children', title: 'Детям'
      }
    ],
    advertising: [],
    posts: [],
    doctorsSpecializations: [],
    personalDataLink: 'https://www.consultant.ru/document/cons_doc_LAW_61801/',


    utm_source: '',
    utm_campaign: '',

    isVisitService: false

  },
  actions: {
    async getClinicInfo({state}) {
      dayjs().locale('ru')

      try {
        let {data} = await axiosInstance.get('clinic/affiliate/');
        state.clinicInfo = data[0];


        let rangeWorkMode = state.clinicInfo.opening_hours;
        let weekdays = rangeWorkMode.map((el, idx) => {
          let str = dayjs().locale('ru').day(idx + 1)
          str = dayjs(str).locale('ru').format('dddd')
          str = str[0].toUpperCase() + str.slice(1);
          return str;
        });


        let weekdaysShort = rangeWorkMode.map((el, idx) => {

          let str2 = dayjs().locale('ru')
            .day(idx + 1)
          str2 = dayjs(str2).locale('ru').format('dd')
          str2 = str2[0].toUpperCase() + str2.slice(1);
          return str2;
        });



        weekdays = `${weekdays[0]} - ${weekdays[weekdays.length - 1]}`;
        let weekdaysShortStr = `${weekdaysShort[0].toUpperCase()} - ${weekdaysShort[weekdaysShort.length - 1].toUpperCase()}`;


        let address, route, phone_number, email, youtube_video;
        state.clinicInfo.affiliate_info.forEach((el) => {
          if (el.type === 'address') {
            address = el.value;
          }

          if (el.type === 'route') {
            route = el.value;
          }
          if (el.type === 'phone_number') {
            phone_number = el.value;
          }
          if (el.type === 'email') {
            email = el.value;
          }
          if (el.type === 'youtube_video') {
            youtube_video = el.value;
          }
        });

        let firstDay = dayjs(rangeWorkMode[0].schedule.open, 'HH:mm:ss');
        let secondDay = dayjs(rangeWorkMode[rangeWorkMode.length - 1].schedule.close, 'HH:mm:ss');


        let isOpen = dayjs().isBetween(firstDay, secondDay, 'hour');

        let affiliateInfo = {
          address,
          route,
          phone_number,
          email,
          youtube_video,
        };

        state.clinicInfo = {
          ...state.clinicInfo,
          messangers: state.clinicInfo.messangers,
          social_networks: state.clinicInfo.social_networks,
          weekdays: weekdays,
          weekdaysShortStr,
          affiliateInfo,
          isOpen,
          firstDay: dayjs(firstDay).format('H:mm'),
          secondDay: dayjs(secondDay).format('H:mm'),
        };
      } catch (e) {
        console.log(e);
      }
    },
    async getAdvertising({state}) {
      if (!state.advertising.length) {
        if (state.currentRout === 'main' || state.currentRout === 'discount') {
          try {
            let {data} = await axiosInstance.get('schedule/adv/?format=json')
            state.advertising = data
          } catch (e) {
            console.log()
          }
        }
      }
    },
    async getPosts({state}) {
      if (state.currentRout === 'main' && !state.posts.length) {
        try {
          const {data} = await axiosInstance.get(
            "clinic/posts/?format=json"
          )
          state.posts = data
        } catch (error) {
          console.error("Failed to fetch data", error)
        }

      }
    },
    updateSelectedReferral({state,}, payload) {
      state.selectedReferral = payload

      if (state.selectedReferral?.patient_category === 'adults and children') {
        state.selectedReferral = {
          ...state.selectedReferral,
          patient_category: 'adults'
        }
      }
    },
  },
  mutations: {
    updateOption(state, {option, payload}) {
      state[option] = payload;
    },

  },
  getters: {
    referralTabs(state) {
      if (!state.selectedReferral?.alt_category) {
        // commit('updateReferralTabs', state.selectedReferral.patient_category)
        return state.referralTabs.filter(el => el.name === state.selectedReferral.patient_category)
      }

      return state.referralTabs
    },
    referralSelectedTab(state) {
      let {selectedReferral} = state
      return state.referralTabs.find(el => el.name === selectedReferral?.patient_category)
    },

  }
});
