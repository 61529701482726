<template>
	<div class="direction">
		<div class="column">
			<!-- Кнопки для  -->
			<div v-for="(direction, index) in tabsDirections" :key="index">
				<router-link :to="`${direction.link}`">
					<div class="wrapper">
						<div
							class="btns font-[500] justify-center leading-7 flex text-[#212121] items-center"
						>
							{{ direction.name }}
						</div>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AllDirections",
	props: {
		tabsDirections: Array,
	},
}
</script>

<style scoped>
.direction {
	@apply mt-12;
}
.btns {
	@apply p-4 text-base w-full h-full;
}
.wrapper {
	@apply bg-[#FFFFFF] border border-[#E8E8E8] rounded-[20px] flex items-center justify-center h-[68px] w-full;
}
.column {
	@apply mt-12 grid grid-cols-4 gap-5;
}

@media (max-width: 1279px) {
	.column {
		@apply grid grid-cols-3 gap-5;
	}
	.wrapper {
		@apply w-full;
	}
}

@media (max-width: 1023px) {
	.column {
		@apply grid grid-cols-2 gap-5;
	}
	.wrapper {
		@apply w-full;
	}
}

@media (max-width: 640px) {
	.direction {
		@apply mt-6;
	}
	.btns {
		@apply p-5 text-base;
	}
	.wrapper {
		@apply bg-[#FFFFFF] rounded-[20px] border border-[#E8E8E8] block text-left  h-[68px] w-full;
	}
	.column {
		@apply grid grid-cols-1 gap-3;
	}
}
</style>
