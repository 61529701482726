<template>
<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.3327 3.94183C12.0974 2.88817 10.52 2.32155 8.8966 2.3483C7.27317 2.37505 5.7153 2.99334 4.51536 4.08713C3.31541 5.18092 2.55589 6.67502 2.37931 8.28904C2.20273 9.90306 2.62125 11.526 3.55631 12.8534C3.72369 13.091 3.75519 13.3986 3.63943 13.6652L2.88312 15.407L5.56217 14.9296C5.73637 14.8985 5.91592 14.9238 6.07483 15.0016C7.53307 15.7155 9.20175 15.8728 10.7677 15.4439C12.3337 15.0149 13.6893 14.0292 14.5801 12.6718C15.4709 11.3143 15.8357 9.67842 15.6061 8.07109C15.3764 6.46376 14.5681 4.9955 13.3327 3.94183ZM1.40304 17.3279C1.48884 17.338 1.57465 17.3344 1.65758 17.3184L5.59427 16.6168C7.35573 17.4074 9.34033 17.5629 11.208 17.0513C13.1655 16.5151 14.86 15.283 15.9735 13.5862C17.087 11.8894 17.5431 9.84451 17.256 7.83535C16.9689 5.82619 15.9585 3.99085 14.4143 2.67378C12.8702 1.3567 10.8984 0.648418 8.86914 0.681857C6.83985 0.715296 4.89252 1.48816 3.39259 2.8554C1.89266 4.22263 0.943249 6.09026 0.722529 8.10779C0.519127 9.96702 0.947438 11.8358 1.93063 13.4161L0.740245 16.1575C0.705469 16.2345 0.682181 16.3172 0.67216 16.403C0.660048 16.506 0.667595 16.6091 0.692824 16.7071C0.722447 16.8231 0.775596 16.9277 0.846152 17.0168C0.981887 17.1883 1.18204 17.3021 1.40304 17.3279Z" fill="#4EA846"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.03283 5.61611C6.26725 5.38169 6.5852 5.25 6.91672 5.25C7.24823 5.25 7.56618 5.38169 7.8006 5.61611C8.03502 5.85053 8.16672 6.16848 8.16672 6.5V7.33333C8.16672 7.66485 8.03502 7.98279 7.8006 8.21721C7.71631 8.30151 7.62121 8.37252 7.51857 8.4289C7.68227 8.89926 7.95084 9.3315 8.30969 9.69035C8.66854 10.0492 9.10079 10.3178 9.57114 10.4815C9.62753 10.3788 9.69854 10.2837 9.78283 10.1994C10.0173 9.96502 10.3352 9.83333 10.6667 9.83333H11.5C11.8316 9.83333 12.1495 9.96502 12.3839 10.1994C12.6184 10.4339 12.7501 10.7518 12.7501 11.0833C12.7501 11.4148 12.6184 11.7328 12.3839 11.9672C12.1495 12.2016 11.8316 12.3333 11.5 12.3333H10.6667C9.34063 12.3333 8.06886 11.8065 7.13118 10.8689C6.1935 9.93118 5.66672 8.65941 5.66672 7.33333V6.5C5.66672 6.16848 5.79841 5.85053 6.03283 5.61611Z" fill="#4EA846"/>
</svg>


  </template>
  
  <script>
  export default {
    name: 'MyWhatsapp',
    props: {},

  };
  </script>
  