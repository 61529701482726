<template>
	<div class="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5">
		<div v-for="(item, index) in menu" :key="index">
			<template >
        <a :href="item.link"
           target="_blank"
           v-if="item.type && item.type === 'corporate-btns'">
          <v-btn
              outlined
              :style="{
							height: '60px',
							fontSize: item.name.length > 20 ? '16px' : '20px',
						}"
              :data-nav-menu-text="item.name"
              class="flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]"
          >
            {{ item.name }}
          </v-btn>
        </a>
				<router-link v-else-if='item?.slug' :to="{ name: 'service', params: { slug: item.slug, id: item.id } }">
					<v-btn
						outlined
						:style="{
							height: '60px',
							fontSize: item.name.length > 20 ? '16px' : '20px',
						}"
            :data-nav-menu-text="item.name"
						class="flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]"
					>
						{{ item.name }}
					</v-btn>
				</router-link>

        <router-link v-else :to="item.link">
          <v-btn
              outlined
              :style="{
							height: '60px',
							fontSize: item.name.length > 20 ? '16px' : '20px',
						}"
              class="flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]"
          >
            {{ item.name }}
          </v-btn>
        </router-link>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		menu: Array,
	},
}
</script>
