<template>
  <section class="cards ">
    <div class="container"  style="position: relative;">
        <div class="swiper-btn  swiper-btn--right"></div>
        <div class="swiper-btn  swiper-btn--left"></div>
      <div class="swiper card-swiper ">
        <div class="swiper-wrapper card-wrapper  ">
          <div
              v-for="(card, idx) in discount"
              :key="card.id"
              :style="{
                backgroundColor: bgColors[idx],
                borderColor: borderColors[idx],
             }"
              class=" swiper-slide  card__slide"
          >
            <div class="flex space-y-[8px] md:space-y-[20px] flex-col">
              <h2 class="card__name">
                {{ card.name }}
              </h2>
              <p class="card__descr " v-html="card.short_info">
              </p>
            </div>

            <router-link :to="{name: 'advertising', params: {id: card.id}}">
              <v-btn
                  outlined
                  class="normal-case bg-white rounded-lg absolute bottom-0 mb-5 md:mb-9 border border-[#E8E8E8]"
              >
                <h4 class="text-base">Подробнее</h4>
              </v-btn>
            </router-link>
          </div>
        </div>

          <div class="swiper-pagination"></div>
      </div>
    </div>
  </section>
</template>

<script>
import Swiper from "swiper"
import {mapState} from "vuex";
import {advertBgMixin} from "@/mixins/AdvertBgMixin.js";
import {Navigation, Pagination} from "swiper/modules";

export default {
  name: "CardsGen",
  mixins: [advertBgMixin],
  data() {
    return {
      swiper: null,
    }
  },
  created() {

    this.initializeSwiper()
  },
  computed: {
    ...mapState({
      discount: ({advertising}) => advertising
    }),

  },
  watch: {
    discount: {
      handler(val, oldVal) {
        if (val.length !== oldVal.length) {
          this.initializeSwiper()
          this.swiper.update()

        }
      }, deep: true
    }
  },
  methods: {
    initializeSwiper() {
      this.$nextTick(() => {
        this.swiper = new Swiper(".card-swiper", {
          spaceBetween: 20,
          // slidesPerView: 3,
          slidesPerGroup: 3,
          modules: [Navigation, Pagination],
          navigation: {
            nextEl: '.swiper-btn--right',
            prevEl: '.swiper-btn--left',
          },
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: function (index, className) {
              return '<div class="' + className + '">' +   "</div>";
            },
          },
          breakpoints: {
            320: {
              slidesPerView: 1,
            },
            540: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 3,
            }
          }
        })
      })
    },
  },
}

</script>

<style>
.swiper-pagination-bullet {
  @apply rounded-full w-[8px] h-[8px] bg-[#C2E1BF]
}

.swiper-pagination-bullet-active  {
  @apply rounded-full w-[8px] h-[8px] bg-[#4caf50]
}

</style>

<style scoped>
.cards {
  @apply py-[100px] ;
}

.card__slide {
  @apply w-full max-w-[100%] sm:max-w-[396px] h-[272px] md:p-9 p-5 border  border-[#E8E8E8] rounded-xl
}

.card__name {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 65px;
  @apply font-[500] cursor-pointer leading-8  text-2xl;
}

.card__descr {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 68px;
  @apply md:text-base text-[13px] leading-6
}


.swiper-btn {
  @apply hidden min-[1300px]:block  w-[40px] h-[40px] bg-[#fff] absolute bg-no-repeat rounded-full bg-center top-[50%] drop-shadow-lg z-20 cursor-pointer
}

.swiper-btn--left {
  transform: translate(-50%, -50%);
  background-image: url("../../../assets/svg/icons/cheveron-left.svg");
  left: 15px;
}

.swiper-btn--right {
  transform: translate(50%, -50%);
  background-image: url("../../../assets/svg/icons/cheveron-right.svg");
  right: 15px;
}


.swiper-pagination {
  display: flex;
  gap: 5px;

  @apply  min-[1300px]:hidden flex justify-center py-8
}



@media (max-width: 640px) {
  .cards {
    @apply py-14 px-[15px] ;
  }
}

</style>
