export const advertBgMixin = {
  data() {
    return {
      bgColors: [
        "#E5F8EB",
        "#E5F3FF",
        "#F7F7F7",
        "#E5F3FF",
        "#F7F7F7",
        "#E5F8EB",
      ],
      borderColors: [
        "#C2E1BF",
        "#CCE7FF",
        "#E8E8E8",
        "#CCE7FF",
        "#E8E8E8",
        "#C2E1BF",
      ],
    }
  }
}