<template>
  <router-link class="logo" to="/">
    <img src="../assets/svg/clinic/logo.svg" alt="logo_eclinic" />
  </router-link>
</template>

<script>
export default {
  name: 'MyLogo',
  props: {},
};
</script>

<style scoped>
.logo {
  @apply flex items-center sm:hidden;
}

.logo img {
  @apply h-[28px] sm:h-full w-full;
}
</style>