<template>

  <div class="swiper-slide ">
    <div
        :style="'background-image: url(' + doctor.image + ')'"
        class="bg-[#F7F7F7] bg-cover rounded-t-lg"
    ></div>
    <!--grid grid-rows-[max-content_1fr_max-content] gap-1-->
    <div class="p-3 flex flex-col gap-3">
      <h2 class="font-[500] text-xl">{{ doctor.name }}</h2>
      <div class="mt-1">
        <span v-for="(specialty, index) in doctor.specialization" :key="index">
          <router-link
              :class="{'disabled-link' : disabledLink}"

              :to="{
              path: '/doctors',
              query: { specialty: specialty.service_type?.slug },
            }"
          >
            <v-btn
                @click.stop
                small
                class="normal-case mr-1 mt-2 bg-[#E5F8EB] border border-[#C2E1BF]"
                outlined
                rounded
                style="font-size: 13px"
                color="success"
            >
              {{ specialty.name }}
            </v-btn>
          </router-link>
        </span>
      </div>

      <div class="flex gap-3 flex-1 items-end">
        <router-link
            :to="{
            name: `specialist`,
            params: {
              id: doctor.id,
              uuid: doctor.uuid,
            },
          }"
            class="min-w-[120px]"
        >
          <v-btn
              @click="scrollToTop"
              block
              outlined
              class="border border-[#E8E8E8] w-full normal-case rounded-lg"
          ><h4 class="text-base">О враче</h4></v-btn
          >
        </router-link>
        <router-link
            :to="{
            name: `specialist`,
            params: {
              id: doctor.id,
              uuid: doctor.uuid,
            },
          }"
            class="w-full"
        >
          <v-btn
              block
              color="success"
              class="w-full normal-case rounded-lg"
              @click="scrollToTop"
          ><h4 class="text-base">Записаться</h4></v-btn
          >
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    doctor: {
      type: Object,
      required: true,
    },
  },
  computed: {
    disabledLink() {
      return this.$route.name === 'specialist'
    },
    filteredSpecialties() {
      return this.doctor.specialties.slice(0, 2);
    },
    additionalSpecialties() {
      return this.doctor.specialties.slice(2);
    },
    showAdditionalButton() {
      return this.doctor.specialties.length > 2;
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
      });
    },
  },
};
</script>

<style scoped>
.swiper-slide {
  height: initial !important;
  grid-template-rows: 400px 1fr;

  border-radius: 12px;
  border: 1px solid #e8e8e8;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  @apply grid
}

.btn {
  @apply hover:text-white hover:bg-[#4EA846] border border-[#E8E8E8] rounded-xl text-[13px] bg-[#FFFFFF] px-3 py-1 text-[#4EA846];
}

.disabled-link {
  opacity: 0.5;
  pointer-events: none;

}

.disabled-link > .v-btn {
  color: var(--grey-content-1) !important;
  background: rgba(0, 0, 0, 0.38);;
}


</style>
