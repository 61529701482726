<template>
  <section class="reviews">
    <div class="container">
      <h2 class="header ">Отзывы наших пациентов</h2>

      <div class="mb-8 relative">
        <div class="swiper reviews__swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide review__slide" v-for="item in reviews"
                          :key="item.id"
                          >
              <div class="review__user">
                <div class="review__photo">
                  <img src="../../../assets/svg/icons/user.svg" alt="">
                </div>
                <b class="leading-[20px]">{{ item.user }}</b>
              </div>

              <div :class="{'review__comment--overflow': isShowMore(item.user_review)}">
                {{ item.user_review }}
              </div>

              <router-link :to="{name:'review', params: {id: item.id}}" class="text-gray-500 font-weight-bold"
                    v-if="isShowMore(item.user_review)"
              >Подробнее</router-link>

              <a :href='item.review_url'
                 class="review__link"
                 target="_blank"
              >Отзыв из {{item.source}}</a>
            </div>

          </div>
            <div class="swiper-pagination"></div>
        </div>

        <div class="swiper-btn swiper-btn--left"

        ></div>
        <div class="swiper-btn swiper-btn--right"></div>
      </div>

      <div class="review__bottom">
        <div>Всего {{reviews.length}} {{decline(reviews.length, reviewsDecline)}} </div>
      </div>
    </div>



  </section>
</template>

<script>

import Swiper from 'swiper';
import 'swiper/css';
import {Navigation, Pagination} from "swiper/modules";
import {mapState} from "vuex";
import {decline} from "@/helpers/decline.js";

export default {
  name: "ReviewsClinic",
  components: {},
  data() {
    return {
      reviewsTitlesList: [
        {id: 1, title: 'Все отзывы', value: 4.6,},
        {id: 2, title: 'Google Maps', value: 4.9, icon: require('../../../assets/svg/icons/google-color.svg')},
        {id: 3, title: 'Яндекс карты', value: 4.3, icon: require('../../../assets/svg/icons/location.svg')},
        {id: 4, title: 'Вконтакте', value: 0, icon: require('../../../assets/svg/icons/vkcom-logo.svg')},
      ],
      currentTab: '',
      reviewsDecline: ['отзыв', 'отзыва', 'отзывов'],
    }
  },
  mounted() {
    this.currentTab = this.reviewsTitlesList[0]

      this.$nextTick(() => {
         new Swiper('.reviews__swiper', {
           slidesPerView: 4,
            modules: [Navigation, Pagination],
           pagination: {
             el: '.swiper-pagination',
             clickable: true,

           },
            spaceBetween: 20,
            navigation: {
              nextEl: '.swiper-btn--right',
              prevEl: '.swiper-btn--left',
            },
           breakpoints: {
             320: {
               slidesPerView: 1,
               spaceBetween: 20,
             },
             640: {
               slidesPerView: 3,

             },
             1024: {
               slidesPerView: 4,

             },
           },
          });

        new Swiper('.reviews__titles-wrapper', {
          breakpoints: {
            320: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              slidesPerView: 3,

            },
            1024: {
              slidesPerView: 4,

            },
          },
        });
      })
  },
  computed: {
    ...mapState({
      reviews: ({reviews}) => reviews
    })
  },
  methods: {
    decline,
    isShowMore(comment) {
      return comment.length > 300
    },
  }
}
</script>

<style>
.reviews__swiper .swiper-pagination-bullet-active {
  @apply bg-gray-600
}
</style>

<style scoped>


.reviews {
  @apply bg-[#F7F7F7] py-[100px];
}


.header {
  @apply text-4xl leading-[48px] font-[500] mb-4;
}

.reviews__titles-wrapper {
  @apply flex justify-start overflow-hidden rounded-2xl bg-[var(--grey-border)] mb-6 max-w-[700px] ml-0
}


.reviews__titles {
  @apply flex  py-1  px-2
}


.reviews__item {
  white-space: nowrap;
  @apply flex items-center gap-1 px-2 rounded-2xl cursor-pointer
}

.reviews__item--current {
  @apply bg-[#fff]
}

.reviews__swiper {
  @apply flex justify-between
}

.review__slide {
  grid-template-rows: repeat(2, max-content) 1fr;
  @apply bg-[#fff] rounded-2xl drop-shadow-lg h-[400px] my-5 p-3 grid items-baseline  sm:max-w-[400px] w-full
}

.review__link {
  @apply text-gray-300 underline self-end cursor-pointer;
}
.review__user {
  @apply flex items-center gap-4 mb-2
}

.review__photo {
  @apply max-w-[40px] w-full h-[40px] rounded-full bg-[var(--grey-border)] flex justify-center items-center
}

.review__comment--overflow {
  -webkit-line-clamp: 10;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.review__bottom {
  @apply grid grid-cols-2
}

.swiper-btn {
  @apply hidden md:block  w-[40px] h-[40px] bg-[#fff] absolute bg-no-repeat rounded-full bg-center top-[50%] drop-shadow-lg z-20 cursor-pointer
}

.swiper-btn--left {
  transform: translate(-50%, -50%);
  background-image: url("../../../assets/svg/icons/cheveron-left.svg");
  left: 0;
}

.swiper-btn--right {
  transform: translate(50%, -50%);
  background-image: url("../../../assets/svg/icons/cheveron-right.svg");
  right: 0;
}

.reviews__swiper .swiper-wrapper {
  @apply mb-5
}



@media (max-width: 640px) {
  .reviews {
    @apply bg-[#F7F7F7] py-[56px] ;
  }

  .header {
    @apply text-2xl;
  }
}
</style>
