<template>
  <main class="w-full">
    <SlideMenuGen />
    <CardsGen />
    <ServicesClinic />
    <SpecialistClinic />
    <ReviewsClinic />
    <social-network :messengers="messenger" />
    <video-clinic :video="video" />
<!--    <LicenseClinic />-->
  </main>
</template>

<script>
import CardsGen from './cards/CardsGen.vue';
import SlideMenuGen from './menu/SlideMenuGen.vue';
import ServicesClinic from './services-clinic/ServicesClinic.vue';
import SpecialistClinic from './specialists/SpecialistClinic.vue';
import ReviewsClinic from './reviews-clinic/ReviewsClinic.vue';
import SocialNetwork from './social-network/SocialNetwork.vue';
import VideoClinic from './video-clinic/VideoClinic.vue';

import { mapState } from 'vuex';
export default {
  name: 'GenPage',
  components: {
    CardsGen,
    SlideMenuGen,
    ServicesClinic,
    SpecialistClinic,
    ReviewsClinic,
    SocialNetwork,
    VideoClinic,
    // LicenseClinic,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      clinicInfo: (state) => state.clinicInfo,
    }),
    video() {
      return this.clinicInfo.affiliate_info;
    },
    messenger() {
      return this.clinicInfo.messangers;
    },
  },
  mounted() {
    this.scrollToTop();
  },
  beforeRouteEnter(to, from, next) {
    // Прокручиваем страницу наверх перед входом на новый маршрут
    next((vm) => {
      vm.scrollToTop();
    });
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
</script>
