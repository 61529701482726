<template>
  <svg
    width="12"
    height="16"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 0C4.44772 0 4 0.447715 4 1C4 1.55228 4.44772 2 5 2H7C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0H5Z"
      fill="#1C1C1C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 3C0 1.89543 0.895431 1 2 1C2 2.65685 3.34315 4 5 4H7C8.65685 4 10 2.65685 10 1C11.1046 1 12 1.89543 12 3V14C12 15.1046 11.1046 16 10 16H2C0.895431 16 0 15.1046 0 14V3ZM3 7C2.44772 7 2 7.44772 2 8C2 8.55229 2.44772 9 3 9H3.01C3.56228 9 4.01 8.55229 4.01 8C4.01 7.44772 3.56228 7 3.01 7H3ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55229 5.44772 9 6 9H9C9.55228 9 10 8.55229 10 8C10 7.44772 9.55228 7 9 7H6ZM3 11C2.44772 11 2 11.4477 2 12C2 12.5523 2.44772 13 3 13H3.01C3.56228 13 4.01 12.5523 4.01 12C4.01 11.4477 3.56228 11 3.01 11H3ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H9C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11H6Z"
      fill="#1C1C1C"
    />
  </svg>
</template>

<script>
export default {
  name: "MyPriceList",
  props: {},
};
</script>
