<template>
  <div class="flex flex-row justify-between">
    <div class="flex space-x-[30px] justify-center  items-center ">

      <router-link to="/">
        <span v-html="logoIcon"></span>
      </router-link>
      <div
          class="flex space-x-[12px] text-[16px] items-center font-normal">
        <h4>Написать</h4>
        <span class="border rounded-[8px] p-[4px] hover:bg-slate-50"
              v-for="item in clinicInfo.messangers"
        >
          <a :href="item.link" target="_blank">
            <MyTg v-if="item.type=='telegram'"/>
            <MyWhatsapp v-if="item.type=='whatsapp'"/>
          </a>
        </span>

      </div>

      <div
          class="flex space-x-[12px] text-[16px] items-center font-normal">
        <h4>Бот</h4>
        <span class="border rounded-[8px] p-[4px] hover:bg-slate-50">
          <a href="https://t.me/eclinicpatientbot" target="_blank">
            <MyTg />
          </a>
        </span>
      </div>

      <div class="flex space-x-[12px] text-[16px] items-center font-normal">
        <h4>Соцсети</h4>
        <span class="border rounded-[8px] p-[4px] hover:bg-slate-50"
              v-for="item in clinicInfo.social_networks">
          <a :href="item.link" target="_blank">
            <MyVk v-if="item.type==='vk'"/>
            <MyTg v-if="item.type==='telegram'"/>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import MyTg from '../../../assets/images/tg.vue';
import MyWhatsapp from '../../../assets/images/whatsapp.vue';
import MyVk from '../../../assets/images/vk.vue';
import {mapState} from 'vuex';
import {logoIcon} from "@/helpers/logo.js";
export default {
  name: 'MyOtherComponent',
  components: {MyVk, MyWhatsapp, MyTg},
  data() {
    return {
      logoIcon,
      telegramWrite: '',
      telegramSocisal: '',
      whWrite: 'https://web.whatsapp.com/',
      whSocisal: '',
      vkWrite: '',
      vkSocials: '',
    };
  },
  mounted() {
    this.getLinks();
  },
  computed: {
    ...mapState({
      clinicInfo: (state) => state.clinicInfo,
    }),
  },
  watch: {
    clinicInfo: {
      handler() {
        this.getLinks();
      },
      deep: true,
    },
  },
  methods: {
    getLinks() {
      this.clinicInfo?.messangers?.map((el) => {
        if (el.type === 'telegram') {
          this.telegramWrite = el.link;
        }
      });

      this.clinicInfo?.social_networks?.map((el) => {
        if (el.type === 'vk') {
          this.vkSocials = el.link;
        }

        if (el.type === 'telegram') {
          this.telegramSocisal = el.link;
        }
      });
    },
  },
};
</script>
