<template>
  <div>
    <div id="map" style="width: 100%; height: 400px"
         :style="isMobile ? {height: '280px'} : {height: '400px'}"
    ></div>
  </div>
</template>

<script>
import DG from "2gis-maps"
import {mapState} from "vuex";

export default {
  name: "MapClinic",
  computed: {
    ...mapState({
      address: ({clinicInfo}) => clinicInfo.affiliateInfo
    }),
    isMobile() {
      return window.innerWidth <= 540
    }
  },

  watch: {
    address(val) {
      if (val) {
        this.initMap()
      }
    }
  },
  methods: {
    initMap() {
      let address = `<div>
    ${this.address?.address}
    <br>
    ${this.address?.route}
</div>`
      DG.then(() => {
        const map = DG.map("map", {
          center: [48.477685, 135.056381],
          zoom: 15,
        })

        DG.marker([48.477685, 135.056381])
            .addTo(map)
            ;

        DG.popup({maxWidth: 300, minWidth: 420})
            .setLatLng([48.477685, 135.056381])
            .setContent(address)
            .openOn(map);


      })
    }
  },
}
</script>
