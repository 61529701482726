<template>
  <section class="services">
    <div class="container">
      <div class="header ">
        <h2 class="header-text leading-[48px] font-[500]">Услуги</h2>
        <div class="border rounded-lg flex items-center bg-[#E5F8EB]">
          <button
              v-for="tab in referralTabs"
              :key="tab.name"
              class="tab"
              :class="{'selected-tab':  selectedAgeTab === tab}"
              @click="selectedAgeTab = tab"
          >
            {{ tab.title }}
          </button>
        </div>
      </div>
      <div class="tabs">
        <div class="description">
          <div class="flex gap-4 sm:gap-6">
            <button
                v-for="tab in tabs"
                :key="tab.name"
                @click="activeTabDirection = tab"
                :class="[
							activeTabDirection === tab
								? 'underline decoration-[2px] underline-offset-[16px] decoration-green-500'
								: 'underline-offset-[16px] hover:underline hover:decoration-[2px] hover:decoration-slate-300',
						]"
            >
              {{ tab.title }}
            </button>
          </div>
        </div>
      </div>


      <div>
        <div v-if="activeTabDirection.name === 'direction'">
<!--          <popular-directions :services="popularDirections"/>-->
        </div>
        <div class="desktop-direction">
          <all-directions :directions="allDirections"/>
        </div>
      </div>

      <div class="mobile-direction mt-5">
        <div class="border border-[#E8E8E8] rounded-xl text-xl bg-white">
          <button
              class="w-full p-5 flex items-center justify-between"
              @click="watch_all = true"
          >
            См. Все направления
            <v-icon color="success">mdi-arrow-right</v-icon>
          </button>
        </div>

        <div v-if="watch_all === true">
          <all-directions :directions="allDirections"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PopularDirections from "./PopularDirections.vue"
import AllDirections from "./AllDirections.vue"
import TabsDirections from "./TabsDirections.vue"
import {mapState} from "vuex";
import {corporateBtnsList} from "@/helpers/corporateBtnsList.js";

export default {
  name: "ServicesClinic",
  components: {
    PopularDirections,
    AllDirections,
    TabsDirections,
  },
  data() {
    return {
      watch_all: false,
      activeTabDirection: "direction",
      tabs: [
        {
          name: "direction",
          title: "Направления",
        },
        {
          name: "diagnostics",
          title: "Диагностика",
        },
        {
          name: "test",
          title: "Исследования",
        },
        // {
        // 	name: "offsite",
        // 	title: "Вызов на дом",
        // },
      ],
      selectedAgeTab: '',
      corporateBtnsList
    }
  },
  mounted() {
    this.selectedAgeTab = this.referralTabs[0]
    this.activeTabDirection = this.tabs[0]
  },
  computed: {
    ...mapState({
      referralTabs: ({referralTabs}) => referralTabs,
      services: ({services}) => services,
    }),
    allDirections() {
      let data = [...this.services]
      if (this.selectedAgeTab.name === 'adults') {
        let filteredData = data.filter(({patient_category, service_group_type}) => {
          return (patient_category === "adults and children" ||
              patient_category === "adults")
        //    && service_group_type === "speciality"
        })

        data = filteredData.concat(this.corporateBtnsList)
      }

      if (this.selectedAgeTab.name === 'children') {
        data = data.filter(({patient_category}) => {
          return patient_category === "children" || patient_category === "adults and children"
        })
      }

      if (this.activeTabDirection.name !== 'direction') {
        data = data.filter(({service_group_type}) => service_group_type === this.activeTabDirection.name)
      }
      return data
    },
    popularDirections() {
      return this.allDirections.filter(({score}) => score !== null)
    },
  },
}
</script>

<style scoped>
.services {
  @apply w-full bg-[#F7F7F7]  py-[100px];
}

.header {
  @apply text-center flex justify-center items-center;
}

.header-text {
  @apply text-4xl mr-9;
}

.tabs {
  @apply mt-12 flex justify-center;
}



.tab {
  @apply w-full h-full p-2  border rounded-lg bg-[#E5F8EB]
}

.selected-tab {
  @apply text-white bg-[#4EA846]
}

.description {
  @apply space-x-[24px] flex text-xl py-2 border-b;
}

.mobile-direction {
  @apply hidden;
}

@media (max-width: 640px) {
  .services {
    @apply px-4 py-[56px];
  }

  .test {
    @apply flex;
  }

  .header {
    @apply text-left flex justify-start;
  }

  .header-text {
    @apply text-2xl mr-4;
  }

  .tabs {
    justify-content: initial;
    @apply mt-6 flex  mx-auto;
  }

  .description {
    @apply space-x-[20px] text-[16px];
  }

  .mobile-direction {
    @apply block;
  }

  .desktop-direction {
    @apply hidden;
  }
}
</style>
