<template>
	<div class="popular">
		<h2 class="header text-2xl leading-8 font-[500]">Популярные направления</h2>
		<div class="directions">
			<div v-for="(service, index) in services" :key="index">
				<router-link :to="{name: 'service', params: {slug: service.slug, id : service.id}}">
					<div class="btn">
						<v-icon large :color="service.color">{{ service.icon }}</v-icon>
						<h3 class="btn-name text-[#212121]">
							{{ service.name }}
						</h3>
					</div>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "PopularDirections",
	props: {
		services: Array,
	},
}
</script>

<style scoped>
.popular {
	@apply mt-12;
}
.btn {
	@apply hover:bg-green-100 cursor-pointer text-center p-10 bg-[#FFFFFF] h-[168px] rounded-[20px] border border-[#E8E8E8];
}
.btn-name {
	@apply mt-4 text-2xl font-[500px];
}
.directions {
	@apply mt-6 grid grid-cols-3 gap-5;
}

@media (max-width: 1279px) {
	.directions {
		@apply mt-6 grid grid-cols-2 gap-5;
	}
}

@media (max-width: 1023px) {
	.directions {
		@apply mt-6 grid grid-cols-2 gap-5;
	}
	.btn-name {
		@apply text-base;
	}
}

@media (max-width: 640px) {
	.header {
		@apply text-[16px] font-[500];
	}
	.directions {
		@apply grid grid-cols-1 gap-3;
	}
	.btn {
		@apply flex p-5 h-[76px] items-center text-xl rounded-[20px];
	}
	.btn-name {
		@apply mt-0 text-xl ml-4;
	}
	.popular {
		@apply mt-6;
	}
}
</style>
