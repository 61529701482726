<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
      fill="#1C1C1C"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5 9.3H7.3V8.1H9.2C10.6 8.1 11.8 6.8 11.8 5.3C11.8 3.8 10.6 2.5 9.2 2.5H6.5C6.1 2.5 5.7 2.9 5.7 3.3V6.4H5C4.6 6.4 4.2 6.8 4.2 7.2C4.2 7.6 4.5 8 5 8H5.7V9.2H5C4.6 9.2 4.2 9.6 4.2 10C4.2 10.4 4.5 10.8 5 10.8H5.7V12.3C5.7 12.8 6 13.1 6.5 13.1C6.9 13.1 7.3 12.7 7.3 12.3V10.8H10.5C10.9 10.8 11.3 10.4 11.3 10C11.2 9.6 10.9 9.3 10.5 9.3ZM9.2 6.4H7.3V4.1H9.2C9.8 4.1 10.2 4.6 10.2 5.3C10.2 6 9.7 6.4 9.2 6.4Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "MyPayment",
  props: {},
};
</script>
