<template>
<svg width="18" height="18" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.1558 1.39759C15.2601 1.07232 15.1558 0.833313 14.6595 0.833313H13.0185C12.6013 0.833313 12.4089 1.03978 12.3046 1.26746C12.3046 1.26746 11.4701 3.17026 10.2879 4.40625C9.90543 4.76403 9.73156 4.87787 9.52293 4.87787C9.41863 4.87787 9.26764 4.76403 9.26764 4.43879V1.39759C9.26764 1.00727 9.14655 0.833313 8.79881 0.833313H6.22008C5.95934 0.833313 5.80252 1.01447 5.80252 1.18616C5.80252 1.55618 6.39359 1.64151 6.45451 2.68237V4.94295C6.45451 5.43858 6.35883 5.52843 6.1502 5.52843C5.59389 5.52843 4.24067 3.61713 3.43808 1.4301C3.2808 1.00502 3.12304 0.833313 2.70364 0.833313H1.06264C0.593777 0.833313 0.5 1.03978 0.5 1.26746C0.5 1.67405 1.05634 3.69069 3.0904 6.35784C4.44643 8.17929 6.35699 9.16665 8.09552 9.16665C9.13862 9.16665 9.26766 8.94735 9.26766 8.56961V7.19296C9.26766 6.75437 9.36648 6.66683 9.6968 6.66683C9.94019 6.66683 10.3574 6.78067 11.331 7.65888C12.4437 8.69972 12.6271 9.16665 13.253 9.16665H14.894C15.3628 9.16665 15.5973 8.94735 15.462 8.51458C15.314 8.08325 14.7828 7.45745 14.0779 6.71562C13.6954 6.29278 13.1217 5.83743 12.9478 5.6097C12.7045 5.317 12.774 5.18687 12.9478 4.92668C12.9478 4.92668 14.9472 2.29205 15.1558 1.39759Z" fill="#0085FF"/>
</svg>



  </template>
  
  <script>
  export default {
    name: 'MyVk',
    props: {},

  };
  </script>
  