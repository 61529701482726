<template>
  <div class="w-full">
    <button class="w-full flex items-center space-x-[19px]">
			<span
          @click.stop="burger = !burger"
          class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
      >
				<h4 class="text-black">{{ name }}</h4>
				<v-icon color="success">mdi-chevron-right</v-icon>
			</span>
    </button>
    <v-navigation-drawer
        v-model="burger"
        absolute
        top
        temporary
        height="100%"
        width="100%"
        class="w-auto z-30"
    >
      <v-list :style="{ paddingLeft: 0, paddingRight: 0 }" nav dense>
        <div class="space-y-[6px] py-5 px-[15px] grid grid-cols-1 gap-6">
          <v-btn
              block
              outlined
              @click.stop="burger = !burger"
              class="normal-case rounded-lg border border-[#E8E8E8] flex items-center"
          >
            <v-icon class="mr-2">mdi-arrow-left</v-icon>
            Назад
          </v-btn>
          <div class="mt-6 grid gap-3">
            <div class="grid" v-for="(nav, index) in navigation" :key="index">
              <router-link :to="{name:'service', params: {slug: nav.slug}}"
                           v-if="name === 'Услуги' && !nav.type"
                           @click.stop="nav.panel = !nav.panel"
                           class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
              >
                <h4 class="text-black">
                  {{ typeof nav === "string" ? nav : nav.name }}
                </h4>
                <v-icon v-if="name === 'Услуги'" color="success"
                >mdi-chevron-right
                </v-icon
                >
              </router-link>

              <a target="_blank" :href="nav.link"
                 v-if="nav.type && nav.type === 'corporate-btns' && name === 'Услуги'"
                           class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
              >
                <h4 class="text-black">
                  {{ typeof nav === "string" ? nav : nav.name }}
                </h4>
                <v-icon v-if="name === 'Услуги'" color="success"
                >mdi-chevron-right
                </v-icon
                >
              </a>

              <div  v-if="name === 'Врачи'">
                <router-link
                    :to="{
										name: 'doctor',
										query: { specialty:  nav.service_type?.slug }
									}"
                >
									<span
                      class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
                  >
										<h4 class="text-black">
											{{ nav.name }}
										</h4>
									</span>
                </router-link>
              </div>
              <div v-if="name === 'О клинике'">
                <router-link  :to="nav?.link">
									<span
                      class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
                  >
										<h4 class="text-black">
											{{ nav.name }}
										</h4>
									</span>
                </router-link>
              </div>
              <v-navigation-drawer
                  v-if="name === 'Услуги'"
                  v-model="nav.panel"
                  absolute
                  top
                  temporary
                  height="100%"
                  width="100%"
                  class="w-auto z-30"
              >
                <v-list :style="{ paddingLeft: 0, paddingRight: 0 }" nav dense>
                  <div
                      class="space-y-[6px] py-5 px-[15px] grid grid-cols-1 gap-6"
                  >
                    <v-btn
                        block
                        outlined
                        @click.stop="nav.panel = !nav.panel"
                        class="normal-case rounded-lg border border-[#E8E8E8] flex items-center"
                    >
                      <v-icon class="mr-2">mdi-arrow-left</v-icon>
                      Назад
                    </v-btn>
                    <div class="grid grid-cols-1 gap-3">
                      <div
                          v-for="navigation in nav.service"
                          :key="navigation.id"
                      >
                        <router-link
                            :to="{
														name: 'service',
														params: { slug: navigation.slug },
													}"
                        >
													<span
                              class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
                          >
														<h4 class="text-black">
															{{ navigation.name }}
														</h4>
													</span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </v-list>
              </v-navigation-drawer>
            </div>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "MainHeader",
  props: {
    name: String,
    navigation: [Array, Set],
  },
  data: () => ({
    burger: false,
  }),

  watch: {
    group() {
      this.burger = false
      this.panel = false
    },
  },
}
</script>
