<template>
  <div class="search w-full">
    <input
        ref="searchInput"
        class="search__input"
        type="text"
        :placeholder="placeholder"
        v-model="searchTerm"
        @focus="emitFocus($event)"

    />

    <div class="search__input-svg">
      <v-progress-circular
          indeterminate
          color="#969696"
          :size="24"
          :width="3"
          v-if="loading"
      ></v-progress-circular>
      <svg v-else
           @click="setFocus"
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 20 20">
        <path
            d="M12.9 14.32a8 8 0 1 1 1.41-1.41l5.35 5.33-1.42 1.42-5.33-5.34zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
        />
      </svg>
    </div>

    <ul
        class="dropdown max-h-[400px] rounded-lg mt-3 border-[1px] overflow-auto"
        v-if="results.length && isSearchInputFocused">
      <li
          v-for="item in results"
          :key="item.id"
          class="py-2 cursor-pointer w-full"
      >
          <span @click.stop.prevent="goTo(item)">
            <h4 class="text-black">{{ item.name }}</h4>
          </span>
      </li>
    </ul>
  </div>
</template>

<script>
import {axiosInstance} from "@/axiosConfig.js";

export default {
  data() {
    return {
      search_panel: true,
      showDropdown: false,
      searchTerm: '',
      active: false,
      results: [],
      windowWidth: window.innerWidth,
      isSearchInputFocused: false,
      loading: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    emitFocus(e) {
      if (e.type === 'focus') {
        this.isSearchInputFocused = true
      }

      if (e.type === 'blur') {
        this.isSearchInputFocused = false
      }

      if (this.windowWidth < 420) {
        this.$emit('search-focus', this.isSearchInputFocused)
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    goTo(val) {
      let routeData = {
        name: val.doctor_id ? 'specialist' : 'service',
        params: {
          id:  val.id,
          uuid: val.uuid ? val.uuid : '',
        },
      };

      if (val?.slug) {
        routeData.params.slug = val.slug
      }

      this.$router.push(routeData).catch((e) => {
        console.log(e);
      });

      this.emitFocus({type: 'blur'})

      this.showDropdown = false;
      this.searchTerm = '';
      this.results = [];
    },
    onClickOutside() {
      this.active = false;
      this.showDropdown = false;
      this.search_panel = true;
      this.searchTerm = '';
    },
    setFocus() {
      this.$nextTick(() => {
        this.$refs.searchInput.focus();
      });
    },
    async globalSearch(val) {
      this.loading = true
      try {
        let {data} = await axiosInstance.get(
            `clinic/search/?query=${val}`
        );

        if (data && data.length) {
          this.results = data;
        }

        if (this.searchTerm && !this.results.length) {
          this.results.push({sysName: 'notFound', name: 'Ничего не найдено'})
        }
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
  },
  watch: {
    searchTerm(val) {
      clearTimeout(this.timer);
      if (val.length > 3) {
        this.timer = setTimeout(async () => {
          await this.globalSearch(val);
          clearTimeout(this.timer);
        }, 1500);
      }
    },
    isSearchInputFocused(val) {
      if (!val) {
        this.searchTerm = ''
        this.results = []
      }
    }
  },
  computed: {
    placeholder() {
      if (this.windowWidth < 640) return ''
      return this.isSearchInputFocused ? 'Поиск услуги' : 'Поиск'
    },
  },
};
</script>

<style scoped>
.search {
  @apply w-full relative max-w-[40px] sm:max-w-[80px];
  transition: max-width .5s ease-in-out;
}

.search__input {
  transition: border .3s ease-in-out;
  @apply w-full max-h-[36px] sm:border border border-solid  border-[#969696] sm:border-transparent text-black rounded-lg p-2 outline-none
}

.search__input::placeholder {
  @apply text-black
}

.search__input-svg {
  transform: translate(-50%, -50%);
  @apply absolute right-[2px] sm:right-auto sm:left-auto pr-0 top-[50%] sm:pr-3 flex items-center
}

.search__input-svg svg {
  @apply w-[18px] fill-[#969696] sm:fill-[#000]
}

.search__input:focus {
  border: 1px solid #969696;
  transition: border .3s ease-in-out;
}

.search__input:focus + .search__input-svg {
  @apply right-0 pr-0 left-auto
}


.search__input:focus + .search__input-svg svg {
  fill: #969696
}

.search__input:focus::placeholder {
  @apply text-[#969696]
}

.search:has(.search__input:focus) {
  max-width: 300px;
  transition: max-width .5s ease-in-out;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 30;
}

</style>
