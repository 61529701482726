<template>
    <div class="hidden min-[640px]:flex ">
      <div
        class="relative flex justify-center work-mode"
        v-click-outside="onClickOutside"
      >
        <button
          @click="toggleDropdown"
          class=" border-b border-[#1C1C1C] border-dashed pr-3 hover:text-green-400 hover:border-green-400"
        >
          Адрес и режим работы
        </button>
        <div
          v-show="isOpen"
          class="absolute left-0 z-20 bg-white p-6 rounded-xl w-[265px] mt-8 border"
        >
          <!-- Dropdown content -->
          <h4 class="font-[500]">{{clinicInfo.affiliateInfo?.address}}</h4>
          <p class="mt-2">{{ clinicInfo.affiliateInfo?.route }}</p>
          <v-divider class="my-4"></v-divider>
          <h2 class="font-[500]">{{ clinicInfo.weekdays }}</h2>
          <h2 class="font-[500]">с {{clinicInfo.firstDay}} до {{clinicInfo.secondDay}}</h2>
          <!-- #e92c2c42 -->
          <!-- #E92C2C -->
          <button
            class="mt-2 py-[4px] rounded-[22px] px-[12px] text-sm flex space-x-[8px] items-center justify-center"
            :class="
              clinicInfo.isOpen
                ? ['bg-[#E5F8EB]', 'text-[#4EA846]']
                : ['bg-[#e92c2c42]', 'text-[#E92C2C]']
            "
          >
            <div
              class="rounded-full w-[8px] h-[8px]"
              :class="[clinicInfo.isOpen ? 'bg-[#4EA846]' : 'bg-[#E92C2C]']"
            ></div>
            <span
              >{{ clinicInfo.isOpen ? 'Сейчас открыто' : 'Сейчас закрыто' }}
            </span>
          </button>
          <v-divider class="my-4"></v-divider>
          <router-link to="/contacts">
            <v-btn
              small
              block
              outlined
              class="rounded-lg border border-[#E8E8E8] normal-case"
              @click="isOpen = false"
              >Смотреть все контакты</v-btn
            >
          </router-link>
        </div>
      </div>

      <div class="hidden border-l pl-3 min-[990px]:block">
        <span >Версия для слабовидящих</span>
      </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState({
      clinicInfo: (state) => state.clinicInfo,
    }),
  },

  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    onClickOutside() {
      this.isOpen = false;
    },
  },
};
</script>


