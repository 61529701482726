<template>
  <svg
    width="18"
    height="14"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.64286 2C3.02167 2 2.5 2.51188 2.5 3.16577V4.37529H6.75V2H3.64286ZM3.64286 0C1.8971 0 0.5 1.42742 0.5 3.16577V8.94116C0.5 10.3934 1.65141 11.5921 3.10538 11.625C3.49453 12.5749 4.42215 13.25 5.51648 13.25C6.61055 13.25 7.538 12.5753 7.92731 11.6256H10.3392C10.7285 12.5753 11.6559 13.25 12.75 13.25C13.8521 13.25 14.7852 12.5653 15.1693 11.6046C16.491 11.4379 17.5 10.3006 17.5 8.94116V7.12541C17.5 6.41137 17.2303 5.71734 16.736 5.19826C16.2401 4.67752 15.5568 4.37529 14.8333 4.37529H14.4953L12.1055 1.23843C11.5122 0.459659 10.5917 0 9.61216 0H3.64286ZM8.75 2V4.37529H11.981L10.5146 2.45047C10.2965 2.16429 9.96252 2 9.61216 2H8.75ZM2.5 6.37529V8.94116C2.5 9.30744 2.77479 9.59205 3.10682 9.62287C3.49682 8.67475 4.4235 8.00135 5.51648 8.00135C6.61053 8.00135 7.53796 8.67607 7.92729 9.62564H10.3392C10.7285 8.67607 11.656 8.00135 12.75 8.00135C13.8163 8.00135 14.7243 8.64223 15.1301 9.55383C15.3464 9.44321 15.5 9.21501 15.5 8.94116V7.12541C15.5 6.91113 15.4185 6.71487 15.2877 6.57752C15.1585 6.44184 14.9939 6.37529 14.8333 6.37529H2.5Z"
      fill="#1C1C1C"
    />
  </svg>
</template>

<script>
export default {
  name: "MyEmergency",
  props: {},
};
</script>
