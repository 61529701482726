<template>
  <header
      class="header main-header w-full text-[16px] justify-center font-[500px] flex flex-col">
    <div class="header__top  border-b  ">
      <div class="container items-center flex justify-between">
        <NavigationMenu/>
        <NavigationMenuR/>
      </div>

    </div>

    <HeaderSecond/>
    <BottomHeader/>
  </header>
</template>

<script>
import SearchPanel from "../bottom-header/SearchPanel.vue"
import NavigationMenu from "../navigation/HeaderLMenu.vue"
import NavigationMenuR from "../navigation/HeaderRMenu.vue"
import SecondNavMenuL from "../secondnav/SecLMenu.vue"
import SecondNavMenuR from "../secondnav/SecRMenu.vue"
import BottomHeader from "../bottom-header/BottomHeader.vue"
import MyTg from "../../../assets/images/tg.vue"
import MyWhatsapp from "../../../assets/images/whatsapp.vue"
import MyVk from "../../../assets/images/vk.vue"
import ModalCall from "../navigation/ModalCall.vue"
import TwoLevelPanel from "./2LvlPanel.vue"
import {mapState} from "vuex"
import HeaderSecond from "@/components/header-footer/header/HeaderSecond.vue";

export default {
  name: "MainHeader",
  components: {
    NavigationMenu,
    SearchPanel,
    NavigationMenuR,
    SecondNavMenuL,
    SecondNavMenuR,
    BottomHeader,
    MyVk,
    MyWhatsapp,
    MyTg,
    ModalCall,
    TwoLevelPanel,
    HeaderSecond
  },
  data: () => ({
    drawer: false,
    burger: false,
    group: null,
    search_panel: false,
    // поиск
    specialty: [],
    doctors: [],
    btnSearch: false,
    direction: [],
    allDirections: [],
    diagnostic: [],
    analyses: [],
    home: [],
  }),

  computed: {
    ...mapState({
      doctorsVuex: state => state.doctors,
      servicesVuex: state => state.services,
    }),
    doctorsData() {
      return this.doctorsVuex.map(doctor => {
        return {
          id: doctor.id,
          name: doctor.name,
          specialization: doctor.specialization,
        }
      })
    },
    specialtyData() {
      const specialtiesSet = new Set(
          this.doctorsVuex.flatMap(doctor =>
              doctor.specialization.map(specialty => specialty.name)
          )
      )
      return specialtiesSet
    },
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize)
      if (this.windowWidth < 640) {
        this.search_panel = false
      }
    })
  },

  methods: {
    closeSearch() {
      this.search_panel = false
    },

    fetchDataServices() {
      let services = [...this.servicesVuex]
      this.services = [
        {
          panel: false,
          name: "Отделения",
          service: services.filter(
              service => service.service_group_type === "speciality"
          ),
        },
        {
          panel: false,
          name: "Диагностика",
          service: services.filter(
              service => service.service_group_type === "diagnostics"
          ),
        },
        {
          panel: false,
          name: "Исследования",
          service: services.filter(
              service => service.service_group_type === "test"
          ),
        },
        // {
        // 	panel: false,
        // 	name: "Вызов на дом",
        // 	service: updatedServices.filter(
        // 		service => service.service_group_type === "offsite"
        // 	),
        // },
      ]
    },
  },

  created() {
    this.fetchDataServices()
  },

  watch: {
    group() {
      this.drawer = false
      this.burger = false
    },
  },
}
</script>

<style scoped>
.header__top {
  @apply py-4 border-b border-[#E8E8E8]
}

.header__top {
  @apply bg-[#f7f7f7] order-2 sm:order-none
}

</style>
