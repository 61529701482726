var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"mob-wrapper"},[_c('div',{staticClass:"menu container"},[_c('div',{staticClass:"header"},[_c('h2',{staticClass:"header-text leading-[64px]"},[_vm._v(" Менеджмент здоровья ")]),_c('router-link',{staticClass:"link__appoint",attrs:{"to":"/direction"}},[_c('v-btn',{staticClass:"btn-appointment",attrs:{"color":"success"}},[_c('h4',{staticClass:"text-base"},[_vm._v("Записаться на приём")])])],1)],1),_c('img',{staticClass:"picture transition duration-150 ease-in-out",class:{ 'fade-out': _vm.transitioning },staticStyle:{"object-fit":"cover","object-position":"top"},attrs:{"src":_vm.doctors[_vm.currentSlideIndex]?.picture,"alt":_vm.doctors[_vm.currentSlideIndex]?.name}}),_c('div',{staticClass:"slide-panel",class:{ 'transition-slide': _vm.transitioning }},[_c('div',{staticClass:"flex justify-between mb-2"},[_c('h2',{staticClass:"doctor-name transition-fade",class:{ 'fade-out': _vm.transitioning }},[_vm._v(" "+_vm._s(_vm.doctors[_vm.currentSlideIndex]?.name)+" ")]),_c('div',{staticClass:"switcher-mobile"},[_c('v-btn',{staticClass:"bg-[#4EA846] hover:bg-[#abd6a7]",attrs:{"id":"кнопка переключения слайдера","icon":"","color":"success"},on:{"click":_vm.nextSlide}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)],1)]),_c('div',{staticClass:"flex gap-2 flex-wrap"},_vm._l((_vm.doctors[_vm.currentSlideIndex]?.specialties),function(specialty,id){return _c('span',{key:id},[_c('router-link',{attrs:{"to":{
									path: '/doctors',
									query: { specialty: specialty.service_type?.slug },
								}}},[_c('v-btn',{staticClass:"text-[#4EA846] normal-case bg-[#E5F8EB] border border-[#C2E1BF] transition-fade",class:{ 'fade-out': _vm.transitioning },staticStyle:{"font-size":"13px"},attrs:{"outlined":"","small":"","rounded":"","color":"success","id":"Направление"}},[_vm._v(_vm._s(specialty.name))])],1)],1)}),0),_c('div',{staticClass:"look-doctors-mob"},[_c('router-link',{attrs:{"to":"/doctors"}},[_c('v-btn',{staticClass:"normal-case rounded-lg bg-white border border-[#E8E8E8]",attrs:{"outlined":"","small":""}},[_c('h4',{staticClass:"text-base"},[_vm._v("Смотреть всех врачей")])])],1)],1),_c('div',{staticClass:"look-doctors"},[_c('router-link',{attrs:{"to":"/doctors"}},[_c('v-btn',{staticClass:"normal-case rounded-lg bg-white border border-[#E8E8E8]",attrs:{"outlined":""}},[_c('h4',{staticClass:"text-base"},[_vm._v("Смотреть всех врачей")])])],1),_c('div',{staticClass:"flex items-center justify-between"},[_c('div',{staticClass:"flex gap-2 mr-6"},[_c('button',{class:[
										_vm.currentSlideIndex === 0
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									],on:{"click":function($event){_vm.currentSlideIndex = 0}}}),_c('button',{class:[
										_vm.currentSlideIndex === 1
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									],on:{"click":function($event){_vm.currentSlideIndex = 1}}}),_c('button',{class:[
										_vm.currentSlideIndex === 2
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									],on:{"click":function($event){_vm.currentSlideIndex = 2}}}),_c('button',{class:[
										_vm.currentSlideIndex === 3
											? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
											: 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
									],on:{"click":function($event){_vm.currentSlideIndex = 3}}})]),_c('v-btn',{staticClass:"bg-[#4EA846] hover:bg-[#abd6a7]",attrs:{"id":"кнопка переключения слайдера","icon":"","color":"success"},on:{"click":_vm.nextSlide}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")])],1)],1)],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }