<template>
  <div class="specialist">
    <div class="container">
      <div class="flex justify-between items-center mb-6">
        <div class="flex gap-4 items-center">
          <h2 class="description text-4xl font-[500] leading-[48px]">
            {{sectionTitle}}
          </h2>
          <v-icon color="black" large>mdi-chevron-right</v-icon>
        </div>

        <div class="hidden md:flex items-center gap-3">
          <div class="flex gap-2 mr-6">
            <div
                v-for="index in totalSlides"
                :key="index"
                :class="[
              currentSlideIndex === index - 1
                ? 'rounded-full w-[8px] h-[8px] bg-[#4EA846]'
                : 'rounded-full w-[8px] h-[8px] bg-[#C2E1BF]',
            ]"
            ></div>
          </div>
          <div class="btns">
            <v-btn
                @click="prevSlide"
                class="mr-3 normal-case"
                :disabled="disabler"
                fab
                small
                color="success"
            >
              <v-icon color="white"> mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
                @click="nextSlide"
                fab
                small
                class="normal-case"
                color="success"
                :disabled="isLastSlide"
            >
              <v-icon color="white"> mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </div>
      </div>
<!--      swiper-container-->
      <div class="swiper doctor-swiper">
<!--        swiper-wrapper-->
        <div class="doctors-wrapper swiper-wrapper">
          <doctor-profile
              v-for="doctor in displayedDoctors"
              :key="doctor.id"
              :doctor="doctor"

          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DoctorProfile from './DoctorProfile.vue';
import Swiper from 'swiper';
import {mapState} from 'vuex';

export default {
  components: {
    DoctorProfile,
  },
  data() {
    return {
      currentSlideIndex: 0,
      itemsPerSlide: 4,
      // doctors: [],
      swiper: null,
      disabler: true,
    };
  },
  mounted() {
    this.initializeSwiper()
  },
  watch: {
    displayedDoctors: {
      handler(val) {
        if (val.length) {
          this.initializeSwiper()
        }
      }, deep: true
    }
  },
  computed: {
    ...mapState({
      doctors: (state) => state.doctors,
    }),
    sectionTitle() {
      if (this.$route.name==='main') {
        return 'Наши специалисты'
      }
      if (this.$route.name==='specialist') {
        return 'Врачи этого направления'
      }
    },
    totalDoctors() {
      return this.doctors.length;
    },
    totalSlides() {
      return Math.ceil(this.totalDoctors / this.itemsPerSlide);
    },
    displayedDoctors() {
      const startIndex = this.currentSlideIndex * this.itemsPerSlide;
      const endIndex = startIndex + this.itemsPerSlide;

      let doctors = this.doctors
      if (this.$route.name === 'specialist') {
        let currentDocSpecializations = doctors.find(el => el.id === +this.$route.params.id)?.specialization.map(el => el.id)
        doctors = doctors.filter(el => el.specialization.some(item => currentDocSpecializations.includes(item.id)))
      }
      return doctors.slice(startIndex, endIndex);
    },
    isLastSlide() {
      return this.currentSlideIndex === this.totalSlides - 1;
    },
  },
  methods: {
    initializeSwiper() {
      this.swiper = new Swiper('.doctor-swiper', {
        spaceBetween: 20,

        breakpoints: {
          320: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          920: {
            slidesPerView: 3,
          },
          1200: {
            slidesPerView: 4,
          },

        }
      });
      this.swiper.update()
    },
    nextSlide() {
      if (this.currentSlideIndex < this.totalSlides - 1) {
        this.currentSlideIndex++;
        this.disabler = false;
        this.swiper?.slideNext();
      }
    },
    prevSlide() {
      if (this.currentSlideIndex > 0) {
        this.currentSlideIndex--;
        this.disabler = false;
        this.swiper?.slidePrev();
      } else if (this.currentSlideIndex === 0) {
        this.disabler = true;
      }
    },
  },
};
</script>

<style scoped>

.doctors-wrapper {
  display: flex;
}

.doctor-slide {
  border: 1px solid red;
}
.specialist {
  @apply py-[100px];
}

.icon {
  @apply ml-4;
}

.swiper-container {
  height: auto;
}


@media (max-width: 640px) {
  .specialist {
    @apply py-[56px] px-5;
  }

  .description {
    @apply text-2xl;
  }

  .icon {
    @apply ml-2;
  }

  .swiper-container {
    @apply flex gap-5 min-w-[100%] w-[316px];
  }
}
</style>

<!--//.swiper-container {-->
<!--//  @apply grid grid-cols-4 gap-5 min-w-[316px] w-full;-->
<!--//}-->
<!--.swiper-wrapper {-->
<!--@apply min-h-[632px]-->
<!--}-->


<!--//.swiper-slide {-->
<!--//  @apply inline-block;-->
<!--//}-->
