var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-5"},_vm._l((_vm.menu),function(item,index){return _c('div',{key:index},[[(item.type && item.type === 'corporate-btns')?_c('a',{attrs:{"href":item.link,"target":"_blank"}},[_c('v-btn',{staticClass:"flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]",style:({
							height: '60px',
							fontSize: item.name.length > 20 ? '16px' : '20px',
						}),attrs:{"outlined":"","data-nav-menu-text":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):(item?.slug)?_c('router-link',{attrs:{"to":{ name: 'service', params: { slug: item.slug, id: item.id } }}},[_c('v-btn',{staticClass:"flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]",style:({
							height: '60px',
							fontSize: item.name.length > 20 ? '16px' : '20px',
						}),attrs:{"outlined":"","data-nav-menu-text":item.name}},[_vm._v(" "+_vm._s(item.name)+" ")])],1):_c('router-link',{attrs:{"to":item.link}},[_c('v-btn',{staticClass:"flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]",style:({
							height: '60px',
							fontSize: item.name.length > 20 ? '16px' : '20px',
						}),attrs:{"outlined":""}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)]],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }