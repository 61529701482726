<template>
  <div class="header-r-menu">
    <div
        class="med-osmotr"
        style="order: -10000;   padding-right: 10px; border-right-width: 1px;">
      <a href="https://b2b.eclinic.center/"
         target="_blank"
         class=""
         style=" color: initial; padding-bottom: 2px">Корпоративным клиентам</a>
    </div>


    <ModalCall :text-btn="textBtn"/>
    <a :href="phoneNumberTrim"
       class="phone"

       style="color: black"
    >
      {{ phoneNumber }}
    </a>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import ModalCall from './ModalCall.vue';

export default {
  props: {
    showDialog: Boolean, // Receive the dialog state as a prop
  },
  components: {
    ModalCall,
  },
  computed: {
    ...mapState({
      phoneNumber: (state) => state.clinicInfo.affiliateInfo?.phone_number,
    }),
    textBtn() {

      return this.$parent.$options.name === 'Footer' ? 'Заказать обратный звонок' : 'Заказать звонок'
    },
    phoneNumberTrim() {
      return (
          this.phoneNumber &&
          'tel:' +
          this.phoneNumber
              .replace('(', '')
              .replace(')', '')
              .replaceAll(' ', '')
              .replaceAll('-', '')
      );
    },
    buttonClass() {
      return {
        underline: !this.isFooter,
        'decoration-dashed': !this.isFooter,
        'underline-offset-8': !this.isFooter,
        'hover:text-green-400': !this.isFooter,
        'hover:border-green-400': !this.isFooter,
        'text-green-400': this.isFooter,
        'border-green-400': this.isFooter,
      };
    },
  },
};
</script>

<style scoped>
.header-r-menu {
  @apply flex w-full sm:w-fit gap-3 justify-between  md:p-[0px]
}

.phone {
  @apply sm:border-l sm:pl-2
}

footer .header-r-menu {
  @apply flex-col-reverse
}

footer .phone {
  font-weight: 500;
  @apply border-transparent text-[20px]
}

footer .med-osmotr {
  border: none;
}

@media (max-width: 768px) {
 header .med-osmotr {display: none}
}
</style>
