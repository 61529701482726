<template>
<svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.0509 0.708187C17.2845 0.914157 17.3838 1.23326 17.3083 1.53546L13.975 14.8688C13.9021 15.1601 13.6783 15.3896 13.389 15.4698C13.0996 15.5499 12.7895 15.4682 12.5773 15.2559L9.09 11.7687L7.16651 14.3333C6.98054 14.5813 6.67231 14.7056 6.36636 14.6559C6.06041 14.6063 5.80729 14.3909 5.70927 14.0969L4.15176 9.42434L1.12716 7.91204C0.83235 7.76463 0.652123 7.45731 0.667405 7.12805C0.682686 6.79879 0.890603 6.50948 1.1978 6.39001L16.1978 0.556677C16.4881 0.443775 16.8172 0.502217 17.0509 0.708187ZM9.59925 9.92092C9.59309 9.91455 9.58681 9.90827 9.58041 9.90207L9.34502 9.66668L12.0891 6.9226C12.4145 6.59717 12.4145 6.06953 12.0891 5.74409C11.7637 5.41866 11.236 5.41866 10.9106 5.74409L7.57725 9.07743C7.25182 9.40286 7.25182 9.9305 7.57725 10.2559L7.89953 10.5782L6.78746 12.061L5.62374 8.56983C5.55385 8.36015 5.40353 8.18683 5.20585 8.08799L3.55389 7.26201L15.3008 2.69379L12.715 13.0367L9.59925 9.92092Z" fill="#0085FF"/>
</svg>

  </template>
  
  <script>
  export default {
    name: 'MyTg',
    props: {},

  };
  </script>
  