export const reCaptchaMixin = {
  data() {
    return {
      siteKey: process.env.VUE_APP_RECAPTCHA_TOKEN,
      recaptcha: ''
    }
  },
  methods: {
    verify (response) {
      this.recaptcha = response
    },
    validate () {
      this.$refs.recaptcha.execute()
    },
    onCaptchaExpired () {
      this.$refs.recaptcha.reset()
    },
  }
}