<template>
	<div>
		<div class="direction">
			<h2 class="text-2xl leading-8 font-[500]">Все направления</h2>
			<div class="column">
				<!-- Кнопки для  -->
				<div v-for="(direction, index) in directions" :key="index">
          <router-link v-if="!direction.type" :to="{name: 'service', params: {
            slug: direction.slug
					}}">
						<div class="wrapper">
							<div
								class="btns font-[500] justify-center leading-7 flex text-[#212121] items-center"
							>
								{{ direction.name }}
							</div>
						</div>
					</router-link>
          <a target="_blank" :href="direction.link"
             v-if="direction.type && direction.type === 'corporate-btns'">
            <div class="wrapper">
              <div
                  class="btns font-[500] justify-center leading-7 flex text-[#212121] items-center"
              >
                {{ direction.name }}
              </div>
            </div>
          </a>
				</div>
			</div>
		</div>
		<div class="mt-12">
			<h4 class="text-[#585757] text-[13px]">
				Скидки, акции, специальные цены не распространяются на пациентов с
				полисами ДМС, а также на медицинские услуги по договорам с юридическими
				лицами.
			</h4>
		</div>
	</div>
</template>

<script>
export default {
	name: "AllDirections",
	props: {
		directions: Array,

	},
}
</script>

<style scoped>
.direction {
	@apply mt-12;
}
.wrapper {
	@apply bg-[#FFFFFF] border border-[#E8E8E8] rounded-[20px] flex items-center justify-center h-[68px] w-full;
}
.btns {
	@apply p-4 text-xl w-full h-full ;
  text-align: center;
}
.column {
	@apply mt-6 grid grid-cols-4 gap-5;
}

@media (max-width: 1279px) {
	.column {
		@apply grid grid-cols-3 gap-5;
	}
	.wrapper {
		@apply w-full;
	}
}

@media (max-width: 1023px) {
	.column {
		@apply grid grid-cols-2 gap-5;
	}
	.wrapper {
		@apply w-full;
	}
}

@media (max-width: 640px) {
	.direction {
		@apply mt-6;
	}
	.column {
		@apply grid grid-cols-1 gap-3;
	}
	.wrapper {
		@apply bg-[#FFFFFF] rounded-[20px] border border-[#E8E8E8] block text-left  h-[68px] w-full;
	}
	.btns {
		@apply p-5 text-xl;
	}
}
</style>
