<template>
  <div ref="headerBottom" :class="{'header--fixed': booleanForHeader}">
    <div class=" header__bottom">
      <div class="container flex justify-between gap-1 w-full" v-click-outside="onClickOutside">
        <v-app-bar-nav-icon
            class="burger"
            @click.stop="isBurgerOpened = !isBurgerOpened"
        ></v-app-bar-nav-icon>

        <MyLogo v-if="!isLogoHidden"/>

        <div class="dropdown-menu">
          <div class="button-container">
            <button
                v-for="(menu, index) in menus"
                :key="index"
                @click="toggleMenu(index, menu)"
                :class="{ active: expandedMenu === index }"
                class="dropdown-name"
            >
              {{ menu.title }}

              <v-icon v-if="expandedMenu !== index && !menu.linkName" color="green"
              >mdi-chevron-down
              </v-icon
              >
              <v-icon v-if="expandedMenu === index && !menu.linkName" color="green"
              >mdi-chevron-up
              </v-icon
              >
            </button>
          </div>
        </div>

        <div class="flex space-x-2 w-full justify-end items-center">
          <search-panel
              @search-focus="isLogoHidden = $event"
          />
          <router-link to="/direction">
            <v-btn class="leading-6 normal-case rounded-lg" color="success"
            ><h4 class="text-base">
              Записаться
              <span class="hidden sm:inline">на приём</span></h4></v-btn
            >
          </router-link>
        </div>
      </div>
      <transition name="menu-slide">
        <div class="menu-content border-b-[1px] shadow-xl rounded-xl bg-white" v-if="expandedMenu !== null" v-click-outside="onClickOutside">
          <div class="menu-items  container">
            <div class="px-5 pt-9 pb-9 ">
              <div
                  v-for="(menu, index) in menus"
                  :key="index"
                  v-show="expandedMenu === index"
              >

                <div :class="[menu.nav_panel ? 'flex space-x-[20px]' : 'hidden']">
                  <button
                      v-for="(tab, index) in tabs"
                      :key="index"
                      @click="setActiveTab($event, tab.direction)"
                      class="btn-direction"
                      :class="[
										activeTabDirection === tab.direction
											? 'underline underline-offset-[19px] decoration-[3px] decoration-green-500'
											: 'hover:underline hover:decoration-slate-200 underline-offset-[19px] decoration-[3px]',
									]"
                  >
                    {{ tab.name }}
                  </button>
                </div>
                <v-divider
                    v-if="tabs.length"
                    :class="[menu.nav_panel ? 'mt-[14px] mb-6' : 'w-[0] mt-0']"
                ></v-divider>
                <div v-if="menu.title === 'Услуги'">
                  <div
                      @click="expandedMenu = null"
                      v-if="activeTabDirection === 'direction'"
                  >
                    <menus-directions :menu="allDirections"/>
                  </div>
                  <div
                      @click="expandedMenu = null"
                      v-if="activeTabDirection === 'diagnostic'"
                  >
                    <menus-directions :menu="diagnostic"/>
                  </div>
                  <div
                      @click="expandedMenu = null"
                      v-if="activeTabDirection === 'analyses'"
                  >
                    <menus-directions :menu="analyses"/>
                  </div>
                  <div
                      @click="expandedMenu = null"
                      v-if="activeTabDirection === 'home'"
                  >
                    <menus-directions :menu="home"/>
                  </div>
                </div>
                <div @click="expandedMenu = null" v-if="menu.title === 'Врачи'">
                  <div
                      class="menu__bottom"
                  >
                    <div v-for="(item, index) in specialtyData" :key="index">
                        <v-btn
                            @click.stop.prevent="goTo({
												name: 'doctor',
												query: { specialty: item.service_type?.slug },
											})"
                            outlined
                            :style="{
													height: '60px',
													fontSize: '20px',
												}"
                            class="flex justify-start font-[500] p-5 normal-case bg-[#FFFFFF] w-full h-full rounded-[20px] border border-[#E8E8E8]"
                        >
                          {{ item.name }}
                        </v-btn>

                    </div>
                  </div>
                </div>
                <div
                    @click="expandedMenu = null"
                    v-if="menu.title === 'О клинике'"
                >
                  <menus-directions :menu="menu.items"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <v-navigation-drawer
        v-model="isBurgerOpened"
        location="left"
        fixed
        top
        temporary
        width="85%"
        class="drawer"
    >
      <v-list :style="{ paddingLeft: 0, paddingRight: 0 }" nav dense>
        <div class="space-y-[6px] py-5 px-[15px] grid grid-cols-1 gap-6">
          <div class="p-5 bg-[#F7F7F7] border border-[#E8E8E8] rounded-[20px]">
            <h2 class="text-xl">+7 (4212) 55-67-39</h2>
            <ModalCall/>
          </div>
          <div class="grid grid-cols-1 gap-3">
            <two-level-panel :name="`Услуги`" :navigation="services"/>
            <two-level-panel :name="`Врачи`" :navigation="specialtyData"/>
            <two-level-panel
                :name="aboutClinic[0].title"
                :navigation="aboutClinic[0].items"
            />
            <div v-for="(nav, index) in navigation" :key="index">
              <router-link :to="nav.rout">
								<span
                    class="cursor-pointer w-full rounded-[20px] p-5 border border-[#E8E8E8] flex justify-between items-center text-xl"
                >
									<h4 class="text-black">{{ nav.name }}</h4>
								</span>
              </router-link>
            </div>
          </div>
          <div class="grid gap-5">
            <div v-for="(inter, index) in interactive" :key="index">
              <router-link :to="inter.rout">
								<span class="flex gap-3">
									<v-icon color="grey">{{ inter.icon }}</v-icon>
									<h5 class="mr-2 text-black">{{ inter.name }}</h5>
								</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="mt-6 bg-[#F7F7F7] py-6 px-[15px] text-center h-[324px]">
          <h2 class="font-[500]">{{clinicInfo.affiliateInfo?.address}}</h2>
          <h5>
            {{ clinicInfo.affiliateInfo?.route }}
          </h5>
          <h4 class="mt-6 font-[500]">{{ clinicInfo.weekdays}}</h4>
          <h4 class="font-[500]">с {{clinicInfo.firstDay}} до {{clinicInfo.secondDay}}</h4>
          <div class="flex justify-center mt-2">
            <button
                v-if="clinicInfo.isOpen"
                class="py-[4px] rounded-[22px] text-[#4EA846] px-[12px] text-sm flex space-x-[8px] items-center justify-center bg-[#E5F8EB]"
            >
              <div class="bg-[#4EA846] rounded-full w-[8px] h-[8px]"></div>
              <span>Сейчас открыто</span>
            </button>
            <button
                v-else
                class="py-[4px] rounded-[22px] text-[#D33A31] px-[12px] text-sm flex space-x-[8px] items-center justify-center bg-[#FCEDEB]"
            >
              <div class="bg-[#D33A31] rounded-full w-[8px] h-[8px]"></div>
              <span>Сейчас закрыто</span>
            </button>
          </div>
          <div class="flex justify-center mt-4 space-x-[24px]">
            <div>
              <h4>Написать</h4>
              <div class="flex gap-2 justify-center mt-2">
								<span class="border rounded-[8px] p-[10px] hover:bg-slate-50"
                      v-for="item in clinicInfo.messangers">
									 <a :href="item.link" target="_blank">
                      <MyTg v-if="item.type=='telegram'"/>
                      <MyWhatsapp v-if="item.type=='whatsapp'"/>
                  </a>
								</span>
              </div>
            </div>

            <div>
              <h4>Соцсети</h4>
              <div class="flex gap-2 justify-center mt-2">
								<span class="border rounded-[8px] p-[10px] hover:bg-slate-50"
                      v-for="item in clinicInfo.social_networks">
									  <a :href="item.link" target="_blank">
                      <MyVk v-if="item.type==='vk'"/>
                      <MyTg v-if="item.type==='telegram'"/>
                    </a>
								</span>
              </div>
            </div>
          </div>
        </div>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MenusDirections from "./MenusDirections.vue"
import SearchPanel from "./SearchPanel.vue"
import {mapGetters, mapState} from "vuex"
import MyTg from "@/assets/images/tg.vue";
import MyWhatsapp from "@/assets/images/whatsapp.vue";
import ModalCall from "@/components/header-footer/navigation/ModalCall.vue";
import MyVk from "@/assets/images/vk.vue";
import TwoLevelPanel from "@/components/header-footer/header/2LvlPanel.vue";
import MyLogo from '@/components/Logo.vue'
import {corporateBtnsList} from "@/helpers/corporateBtnsList.js";

export default {
  components: {
    TwoLevelPanel, MyVk, ModalCall, MyWhatsapp, MyTg,
    MenusDirections,
    SearchPanel,
    MyLogo
  },
  data() {
    return {
      isLogoHidden: false,
      isBurgerOpened: false,
      aboutClinic: [
        {
          nav_panel: false,
          title: "О клинике",
          items: [
            {name: "Наши специалисты", link: "/doctors"},
            {name: "Прайс-лист", link: "/price"},
            {name: "Правовая информация", link:  "/pravovaya-informaciya"},
            {name: "Контролирующие органы", link: "/kontroliruyushie-organy"},
            {name: "Реквизиты", link: "/rekvezity"},
          ],
        }, // Ваши данные для меню клиники
      ],
      navigation: [
        {
          name: "Акции",
          rout: "/discount",
        },
        {
          name: "Контакты",
          rout: "/contacts",
        },
      ],
      interactive: [
        // {
        // 	rout: "",
        // 	name: "Вызов на дом",
        // 	icon: "mdi-car-estate",
        // },
        {
          rout: "/pay",
          name: "Оплата онлайн",
          icon: "mdi-currency-rub",
        },
        {
          rout: "/price",
          name: "Прайс-лист",
          icon: "mdi-clipboard-text",
        },
      ],
      tabs: [
        {
          name: "Отделения",
          direction: "direction",
        },
        {
          name: "Диагностика",
          direction: "diagnostic",
        },
        {
          name: "Исследования",
          direction: "analyses",
        },
        // {
        // 	name: "Вызов на дом",
        // 	direction: "home",
        // },
      ],
      search_panel: true,
      menus: [
        {
          nav_panel: false,
          title: "Врачи",
        },
        {
          nav_panel: false,
          title: "Акции",
          linkName: 'discount'
        },
        {
          nav_panel: true,
          title: "Услуги",
        },

        {
          nav_panel: false,
          title: "О клинике",
          items: [
            {name: "Наши специалисты", link: "/doctors"},
            {name: "Прайс-лист", link: "/price"},
            {name: "Правовая информация", link: "/pravovaya-informaciya"},
            {name: "Контролирующие органы", link: "/kontroliruyushie-organy"},
            {name: "Реквизиты", link: "/rekvezity"},
          ],
        },
        {
          nav_panel: false,
          title: "Контакты",
          linkName: 'contacts'
        },
      ],

      expandedMenu: null, // Индекс открытого меню

      // услуги
      activeTabDirection: "direction",
      allDirections: [],
      diagnostic: [],
      analyses: [],
      home: [],
      // врачи
      direction: [],
      specialty: [],
      doctors: [],
      headerHeight: 0,
      booleanForHeader : false,
      corporateBtnsList
    }
  },
  computed: {
    ...mapState({
      doctorsVuex: state => state.doctors,
      services: state => state.services.concat(corporateBtnsList),
      clinicInfo: (state) => state.clinicInfo,
      specialtyData: (state) => state.doctorsSpecializations,
    }),
    doctorsData() {
      return this.doctorsVuex.map(doctor => {
        return {
          id: doctor.id,
          name: doctor.name,
          specialization: doctor.specialization,
        }
      })
    },

  },
  watch: {
    doctorVuex: {
      handler(val) {
        const specialtiesSet = new Set(
            val.flatMap(doctor =>
                doctor.specialization.map(specialty => specialty.name)
            )
        )
        this.doctors = val.map(doctor => {
          return {
            id: doctor.id,
            name: doctor.name,
            specialization: doctor.specialization,
          }
        })
        this.specialty = [...specialtiesSet]
      },
      deep: true,
    },
    services: {
      handler() {
        this.fetchDataServices()
      },
      deep: true,
    },
  },

  methods: {
    goTo(pageParams) {
      this.$router.push(pageParams).catch(e=>console.log(e))
      this.expandedMenu = null
    },
    setActiveTab(e, direction) {
      e.stopPropagation()
      e.preventDefault()
      // console.log(direction)
      this.activeTabDirection = direction
    },
    toggleMenu(index, menu) {
      if (menu.linkName) {
        this.$router.push({name: menu.linkName}).catch(console.log)
        this.expandedMenu = null
        return
      }
      if (this.expandedMenu === index) {
        this.expandedMenu = null // Закрыть открытое меню
      } else {
        this.expandedMenu = index // Открыть выбранное меню
      }
    },
    onClickOutside(e) {
      if (e.target.classList.contains('btn-direction') || e.target.classList.contains('dropdown-name')) return
      this.expandedMenu = null
    },
    fetchDataServices() {
      const updatedServices = this.services

      this.allDirections = updatedServices
          .filter(service => service.service_group_type === "speciality").concat(this.corporateBtnsList)

      this.diagnostic = updatedServices
          .filter(service => service.service_group_type === "diagnostics")


      this.analyses = updatedServices
          .filter(service => service.service_group_type === "test")


      this.home = updatedServices
          .filter(service => service.service_group_type === "offsite")
    },
    fixedHeaderBottom(e) {
      let scrollDistance = window.scrollY

      this.booleanForHeader = scrollDistance >= this.headerHeight;
    }
  },
  mounted() {
    this.fetchDataServices()
    this.$nextTick(() => {
      this.headerHeight = document.querySelector('.main-header').offsetHeight
      window.addEventListener('scroll', this.fixedHeaderBottom);
    })
  },
}
</script>

<style scoped>
.menu-slide-enter-active,
.menu-slide-leave-active {
  transition: all 0.3s;
}

.header--fixed {
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.menu__bottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 20px;
}

.header__bottom {
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  @apply py-4 border-b border-[#E8E8E8] relative
}

.burger {
  @apply min-[930px]:hidden
}

.dropdown-menu {
  @apply flex items-center;
}

.menu-slide-enter,
.menu-slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

.drawer {
  @apply z-30
}

.dropdown-name {
  @apply text-xl;
}

.button-container {
  gap: 30px;
  font-size: 20px;
  font-weight: 500;
  align-items: center;
  width: max-content;
  @apply hidden min-[930px]:flex
}

.active {
  @apply font-[500];
}

.menu-content {
  position: absolute;
  top: 69px;
  left: 0;
  right: 0;
  z-index: 20;

  //max-width: 1412px;
}

@media (max-width: 1279px) {
  .dropdown-name {
    @apply text-[16px];
  }

  .button-container {
    gap: 15px;
  }
}
</style>
