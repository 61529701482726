<template>
  <footer class="container">
    <MapClinic/>
    <div
        class="flex py-[36px] md:py-[74px] bg-[#ffffff] w-full justify-center border-t border-[#E8E8E8] mt-auto"
    >
      <div
          :style="padding"
          class="flex w-full justify-between flex-col space-y-[54px]"
      >
        <div
            class="flex flex-wrap flex-col items-center md:justify-between md:space-y-[0px] space-y-[36px] md:flex-row"
        >
          <span @click="goTo" >
            <img src="../../../assets/svg/clinic/logo.svg" alt="logo_eclinic"/>
          </span>
          <div
              class="flex md:flex-row flex-col items-center w-full md:w-auto space-y-[12px] md:space-y-[0px] md:space-x-[20px]"
          >
            <router-link class="w-full flex" to="/direction">
              <v-btn color="success" class="normal-case w-full rounded-lg"
              ><h4 class="text-base">Записаться на прием</h4></v-btn
              >
            </router-link>
            <!--						<router-link class="w-full flex" to="#">-->
            <!--							<v-btn-->
            <!--								outlined-->
            <!--								class="normal-case border w-full border-[#E8E8E8] rounded-lg"-->
            <!--							>-->
            <!--&lt;!&ndash;								<MyEmergency />&ndash;&gt;-->
            <!--&lt;!&ndash;								<h4 class="ml-3 text-black text-base">Вызов на дом</h4>&ndash;&gt;-->
            <!--							</v-btn>-->
            <!--						</router-link>-->
            <router-link class="w-full flex" to="/price">
              <v-btn
                  outlined
                  class="normal-case w-full border border-[#E8E8E8] rounded-lg"
              >
                <MyPriceList/>
                <h4 class="ml-3 text-black text-base">Прайс-лист</h4></v-btn
              >
            </router-link>
            <router-link class="w-full flex"
                         :to="{name: 'payment'}"
                         v-if="!isOnlinePaymentHidden"
            >
              <v-btn
                  outlined
                  class="normal-case w-full border border-[#E8E8E8] rounded-lg"
              >
                <MyPayment/>
                <h4 class="ml-3 text-black text-base">Оплата онлайн</h4>
              </v-btn>
            </router-link>

            <!-- <second-nav-menu-r
              :isFooter="true"
              :buttonOrder="['payment', 'price', 'test']"
              :showAdditionalButton="true"
            /> -->
          </div>
        </div>

        <div
            class="flex flex-col md:flex-row w-full  px-[55px] md:px-[0px] justify-between"
        >
          <!-- Начало нижнего футера написать/соцсети -->
          <div class="flex w-full md:w-[396px] mb-8 md:mb-0 flex-col relative">
            <div class="flex w-full socials flex-row">
              <div class="social">
                <div
                    class="flex space-y-[8px] md:space-y-[0px] flex-col md:flex-row text-[16px] items-center font-normal space-x-[0px] md:space-x-[16px]"
                >
                  <h4>Написать</h4>
                  <div class="flex flex-row space-x-[12px]">
                    <a target="_blank"
                       :href="affiliateData.tgMes"
                       class="border rounded-[8px] p-[4px] hover:bg-slate-50"
                    >
                      <MyTg/>
                    </a>
                    <a target="_blank"
                       :href="affiliateData.waMes"
                       class="border rounded-[8px] p-[4px] hover:bg-slate-50"
                    >
                      <MyWhatsapp/>
                    </a>
                  </div>
                </div>


                <div
                    class="flex space-x-[12px] text-[16px] items-center font-normal">
                  <h4>Бот</h4>
                  <span class="border rounded-[8px] p-[4px] hover:bg-slate-50">
                    <a href="https://t.me/eclinicpatientbot" target="_blank">
                      <MyTg />
                    </a>
                  </span>
                </div>

                <div
                    class="flex space-y-[8px] md:space-y-[0px] flex-col md:flex-row text-[16px] items-center font-normal space-x-[0px] md:space-x-[16px]"
                >
                  <h4>Соцсети</h4>

                  <div class="flex flex-row space-x-[12px]">
                    <a target="_blank"
                       :href="affiliateData.vkNet"
                       class="border rounded-[8px] p-[4px] hover:bg-slate-50"
                    >
                      <MyVk/>
                    </a>
                    <a target="_blank"
                       :href="affiliateData.tgNet"
                       class="border rounded-[8px] p-[4px] hover:bg-slate-50"
                    >
                      <MyTg/>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <span class="hidden md:block mt-12 asd"
            >Общество с ограниченной ответственностью «Центр биотической медицины и профилактики» (ООО&nbsp;«ЦБМ&nbsp;и&nbsp;П»)</span
            >
          </div>

          <v-row
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-[max-content_max-content_max-content] gap-0 space-y-4 md:space-y-0 md:space-y-0 lg:gap-[48px] justify-end"
          >
            <div
                class="flex flex-col items-center md:items-start space-y-[8px] md:space-y-[12px]"
            >
<!--              <h3 class="leading-7">{{ affiliateData.phoneNumber }}</h3>-->

              <div class="flex justify-center">
                <header-r-menu
                    :showDialog="dialogVisible"
                    @open-dialog="openDialog"
                />
              </div>
            </div>

            <div
                class="route flex flex-col items-center text-center md:items-start w-full space-y-[8px] md:space-y-[12px]"
            >
              <h3 class="leading-7">{{ affiliateData.addressClinic }}</h3>
              <div :class="{ 'text-md-left': !isMobile }">
                {{ route && route[0] }}.
                <br>
                {{ route && route[1]}}
              </div>
              <div :class="{ 'text-md-left': !isMobile }">
                <a :href="'mailto:'+clinicInfo.affiliateInfo?.email"
                   class="!text-[#4EA846]">{{ clinicInfo.affiliateInfo?.email }}</a>
              </div>
            </div>
            <div
                class="flex flex-col md:mb-0 w-full items-center md:items-start lg:items-end space-y-[8px] md:space-y-[12px]"
            >
              <div class="custom-text-left space-y-[16px] leading-7">
                <h3>{{clinicInfo.weekdaysShortStr}}</h3>
                <h3>{{clinicInfo.firstDay}} - {{clinicInfo.secondDay}}</h3>
                <button
                    v-if="clinicInfo.isOpen"
                    class="py-[4px] rounded-[22px] text-[#4EA846] px-[12px] text-sm flex space-x-[8px] items-center justify-center bg-[#E5F8EB]"
                >
                  <div class="bg-[#4EA846] rounded-full w-[8px] h-[8px]"></div>
                  <span>Сейчас открыто</span>
                </button>
                <button
                    v-else
                    class="py-[4px] rounded-[22px] text-[#D33A31] px-[12px] text-sm flex space-x-[8px] items-center justify-center bg-[#FCEDEB]"
                >
                  <div class="bg-[#D33A31] rounded-full w-[8px] h-[8px]"></div>
                  <span>Сейчас закрыто</span>
                </button>
              </div>
              <!-- {{ openHours }} -->
            </div>
          </v-row>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import MapClinic from "./map/MapClinic.vue"
import MyTg from "../../../assets/images/tg.vue"
import MyWhatsapp from "../../../assets/images/whatsapp.vue"
import MyVk from "../../../assets/images/vk.vue"
import HeaderRMenu from "../../header-footer/navigation/HeaderRMenu.vue"
import MyPayment from "../../../assets/images/vector/payment.vue"
import MyPriceList from "../../../assets/images/vector/pricelist.vue"
// import MyEmergency from "../../../assets/images/vector/emergency.vue"
import {mapState} from "vuex"

export default {
  name: "Footer",
  components: {
    MyTg,
    MyWhatsapp,
    MyVk,
    MapClinic,
    HeaderRMenu,
    MyPayment,
    MyPriceList,
    // MyEmergency,
  },
  data() {
    return {
      isMobile: false,
      dialogVisible: false,

      info: [],
      networks: [],
      messengers: [],
      affiliate: [],
      openHours: [],
      padding: "",
    }
  },
  mounted() {
    this.isMobile = window.innerWidth < 960
    window.addEventListener("resize", this.handleResize)

    // if (this.affiliate.length > 0) {
    this.affiliate = this.clinicInfo.affiliate_info
    this.networks = this.clinicInfo.social_networks
    this.messengers = this.clinicInfo.messangers
    this.openHours = this.clinicInfo.opening_hours
    // }

    if (this.$route.name === "service") {
      this.padding = {
        paddingLeft: 0,
        paddingRight: 0,
      }
    }
  },
  computed: {
    ...mapState({
      clinicInfo: state => state.clinicInfo,
    }),
    isOnlinePaymentHidden() {
      return this.$route.name === 'payment'
    },
    route() {
      return this.clinicInfo.affiliateInfo?.route.split('.');

    },
    affiliateData() {
      // info
      const addressItem = this.clinicInfo.affiliate_info?.length
          ? this.clinicInfo?.affiliate_info?.find(item => item.type === "address")
          : ""

      const phoneNumber = this.clinicInfo.affiliate_info?.find(
          item => item.type === "phone_number"
      )
      const howRoute = this.clinicInfo.affiliate_info?.find(
          item => item.type === "route"
      )
      const emailAddress = this.clinicInfo.affiliate_info?.find(
          item => item.type === "email"
      )
      // network
      const vkNetwork = this.clinicInfo.social_networks?.find(
          item => item.type === "vk"
      )
      const tgNetwork = this.clinicInfo.social_networks?.find(
          item => item.type === "telegram"
      )
      // // social
      const waMessenger = this.clinicInfo.messangers?.find(
          item => item.type === "whatsapp"
      )
      const tgMessenger = this.clinicInfo.messangers?.find(
          item => item.type === "telegram"
      )

      return {
        addressClinic: addressItem ? addressItem.value : "",
        phoneNumber: phoneNumber ? phoneNumber.value : "",
        howRoute: howRoute ? howRoute : "",
        emailAddress: emailAddress ? emailAddress.value : "",

        vkNet: vkNetwork ? vkNetwork.link : "",
        tgNet: tgNetwork ? tgNetwork.link : "",

        waMes: waMessenger ? waMessenger.link : "",
        tgMes: tgMessenger ? tgMessenger.link : "",
      }
    },
    isCurrentlyOpen() {
      const now = new Date()
      const currentDayOfWeek = now.getDay()
      const currentHour = now.getHours()

      if (
          this.clinicInfo &&
          this.clinicInfo.opening_hours &&
          this.clinicInfo.opening_hours.length > 0
      ) {
        const schedule = this.clinicInfo.opening_hours.find(
            item => item.weekday === this.getDayOfWeekString(currentDayOfWeek)
        )

        if (schedule) {
          const openTime = new Date(`1970-01-01T${schedule.schedule.open}`)
          const closeTime = new Date(`1970-01-01T${schedule.schedule.close}`)

          if (
              currentHour >= openTime.getHours() &&
              currentHour < closeTime.getHours()
          ) {
            return true
          }
        }
      }

      return false
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize)
  },

  methods: {
    goTo() {
      if (this.$route.name !== 'main') {
        this.$router.push({name: 'main'}).catch(e=> console.log(e))
      } else {
        window.scrollTo(0, 0, )
      }
    },
    handleResize() {
      this.isMobile = window.innerWidth < 960
    },
    openDialog() {
      this.dialogVisible = true
    },
    getDayOfWeekString(dayOfWeek) {
      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ]
      return daysOfWeek[dayOfWeek]
    },
  },
}
</script>

<style scoped>
.custom-text-left {
  text-align: left !important;
}

.social {
  @apply flex justify-center space-x-[30px] items-center;
}

.socials {
  @apply flex items-center  space-x-[30px]  w-full justify-center md:!justify-start;
}

.social {
  @apply grid gap-4 space-x-0;
}

@media (max-width: 1095px) {


  .socials {
    @apply flex items-center;
  }
}

@media (max-width: 640px) {
  .socials {
    @apply flex justify-center;
  }

  .custom-text-left {
    text-align: center !important;
  }
}

@media (max-width: 1024px) {
  .route {
    margin-bottom: -16px;
  }

  .asd {
    max-width: 260px;
  }
}

</style>
