<template>
  <div class="flex justify-center items-center gap-3">
    <router-link v-if="showAdditionalButton" class="w-full" to="/direction">
      <v-btn
          class="border border-[#E8E8E8] rounded-lg w-full success md:w-auto"
          outlined
      >
        <h4 class="text-white normal-case text-base">
          Записаться на прием
        </h4>
      </v-btn>
    </router-link>
    <router-link
        v-for="buttonId in buttonOrder"
        :key="buttonId"
        class="w-full"
        :to="`/${buttonId}`"
        v-if="((buttonId !== 'payment') && isOnlinePaymentHidden) || !isOnlinePaymentHidden"
    >
      <v-btn
          class="border border-[#E8E8E8] rounded-lg w-full md:w-auto"
          outlined

      >
        <component :is="getButtonIcon(buttonId)"/>
        <h4 class="text-black normal-case ml-2 text-base">
          {{ getButtonText(buttonId) }}
        </h4>
      </v-btn>
    </router-link>
  </div>
</template>

<script>
import MyPayment from "../../../assets/images/vector/payment.vue"
import MyPriceList from "../../../assets/images/vector/pricelist.vue"
import MyEmergency from "../../../assets/images/vector/emergency.vue"

export default {
  name: "MyOtherComponent",
  components: {MyEmergency, MyPayment, MyPriceList},
  props: {
    buttonOrder: {
      type: Array,
      default: () => [],
    },
    showAdditionalButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOnlinePaymentHidden() {
      return this.$route.name === 'payment'
    }
  },
  methods: {
    getButtonIcon(buttonId) {
      // Return the corresponding icon component based on the buttonId
      switch (buttonId) {
        case "pay":
          return MyPayment
        case "price":
          return MyPriceList
        case "test":
          return MyEmergency
        default:
          return null
      }
    },
    getButtonText(buttonId) {

      switch (buttonId) {
        case "pay":
          return "Оплата онлайн"
        case "price":
          return "Прайс-лист"
          // case "test":
          // 	return "Вызов на дом"
          // Add more cases for additional buttons
        default:
          return ""
      }
    },
  },
}
</script>
