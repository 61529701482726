import Vue from "vue"
import VueRouter from "vue-router"

const PageStock = () => import("./components/stock/PageStock.vue")
import GenPage from "./components/gen-page/GenPage.vue"
const  PaymentPage = () => import("@/pages/PaymentPage.vue");

const RequisitesPage = () => import("@/pages/RequisitesPage.vue");
const RegulatoryAuthorities = () => import("@/pages/RegulatoryAuthorities.vue");
const LegalInformation = () => import("@/pages/LegalInformation.vue");
const ChoiceDirection = () => import("./components/choice-direction/ChoiceDirection.vue")
const AppointmentClinic = () => import("./components/appointment/AppointmentClinic.vue")
const ReferralPage = () => import("./components/referral-page/ReferralPage.vue")
const HotTricks = () => import("./components/hot-tricks/HotTricks.vue")
const Doctors = () => import("./components/doctors/Doctors.vue")
const DoctorPage = () => import("./components/doctor-page/DoctorPage.vue")
const PriceListVue = () => import("./components/price-list/PriceList.vue")
const ContactsPage = () => import("./pages/ContactsPage.vue")
const InfoPage = () => import("./components/info-page/InfoPage.vue")
const ArticlesPage = () => import("./components/articles-page/ArticlesPage.vue")
const SuccessPayment = () => import("@/pages/SuccessPayment.vue");
const ErrorPayment = () => import("@/pages/ErrorPayment.vue");
const AdvertisingPage = () => import("@/pages/AdvertisingPage.vue");
const ReviewPage = () => import("@/pages/ReviewPage.vue");


const FeedbackPage = () => import('@/pages/Feedback.vue')

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return {x: 0, y: 0};
  },
  routes: [
    // Redirect all unknown paths to the NotFound component
    {path: "/", component: GenPage, name: 'main'},
    {path: "/discount", name: 'discount', component: PageStock},
    {path: "/direction", component: ChoiceDirection},
    {path: "/clinic", name: "clinic", component: AppointmentClinic},
    {
      path: "/service/:slug",
      name: "service",
      component: ReferralPage,
      props: true,
    },
    {
      path: "/tricks",
      component: HotTricks,
      props: true,
    },
    {path: "/doctors", name: 'doctor', component: Doctors},
    {
      path: "/specialist/:id",
      component: DoctorPage,
      name: "specialist",
      props: route => ({query: route.query.q}),
    },
    {path: "/price", component: PriceListVue},
    {path: "/contacts", name: 'contacts', component: ContactsPage},
    {path: "/pay/", name: 'payment', component: PaymentPage},
    {path: "/info", component: InfoPage},
    {path: "/article", component: ArticlesPage},
    {path: "/success_payment", component: SuccessPayment},
    {path: "/error_payment", component: ErrorPayment},
    {path: "/advertising/:id", name: 'advertising', component: AdvertisingPage},
    {path: "/review/:id", name: 'review', component: ReviewPage},
    {path: "/feedback/complete", name: 'feedbackComplete', component: FeedbackPage},
    {path: "/rekvezity", name: 'requisites', component: RequisitesPage},
    {path: "/kontroliruyushie-organy", name: 'kontroliruyushie-organy', component: RegulatoryAuthorities},
    {path: "/pravovaya-informaciya", name: 'pravovaya-informaciya', component: LegalInformation},


    {name: "404", path: "/404", component: ArticlesPage},
    {path: "*", redirect: "404"}, // Add the NotFound component route
  ],
})

export default router
