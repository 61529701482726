<template>
    <div class=" header__second ">
      <div class="container header__second-container">
        <SecondNavMenuL/>
        <SecondNavMenuR
            :isFooter="true"
            :buttonOrder="['pay', 'price', ]"
            :showAdditionalButton="false"
        />
      </div>

    </div>
</template>

<script>
import SecondNavMenuR from "@/components/header-footer/secondnav/SecRMenu.vue";
import SecondNavMenuL from "@/components/header-footer/secondnav/SecLMenu.vue";

export default {
  name: 'HeaderSecond',
  components: {SecondNavMenuL, SecondNavMenuR}
}
</script>

<style  scoped>
.header__second {
  @apply border-b border-[#E8E8E8]
}

.header__second-container {
  @apply sm:flex flex-wrap justify-center  min-[931px]:justify-between gap-5 bg-white hidden sm:block py-4
}
</style>